import React, { ReactNode } from 'react';
import { Box, styled, Theme, Typography } from '@material-ui/core';
import { Breakpoints, GrayColor } from 'utils/styles/constants';
import { t } from 'i18next';

export interface ISimpleTaskListHeaderProps {
    header?: string | ReactNode;
    regionName?: string | ReactNode;
    customComponent?: ReactNode;
    styles?: string;
}

const getCommonStyles = (theme: Theme) => ({
    fontFamily: `'Inter', sans-serif`,
    fontStyle: 'normal',
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(4) + 'px',
    letterSpacing: 0,
});

const StrongTasksLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    ...getCommonStyles(theme),
}));

export const StyledDotWrapper = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: GrayColor.Gray9E,
    margin: '0 10px',
    ...getCommonStyles(theme),

    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        fontSize: '20px',
        lineHeight: '26.68px',
    },
}));

const StyledRegionName = styled(Box)(({ theme }) => ({
    fontWeight: 400,
    ...getCommonStyles(theme),
}));

const StyledCustomComponent = styled(Box)(({ theme }) => ({
    ...getCommonStyles(theme),
}));

export const SimpleTaskListHeader = React.forwardRef<HTMLDivElement, ISimpleTaskListHeaderProps>(({
    header = `${t('Tasks')}`,
    regionName = `${t('All Regions')}`,
    customComponent,
    styles = ''
}, ref) => (
    <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        className={styles}
        // @ts-ignore - ref is actually present as property of this container
        ref={ref}
    >
        <StrongTasksLabel>{header}</StrongTasksLabel>
        <StyledDotWrapper>•</StyledDotWrapper>
        <StyledRegionName>{regionName}</StyledRegionName>
        { customComponent && <StyledCustomComponent>{customComponent}</StyledCustomComponent> }
    </Box>
));
