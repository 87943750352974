import { delay, put, select, takeEvery } from 'typed-redux-saga';
import { OPEN_SIDE_OUT_TASKS_BULK, resetTaskBulkFile, TasksBulkActions } from './tasksBulkActions';
import { requestDeleteTaskBulk, requestTasksBulkHistory } from 'api/task/tasksBulk/tasksBulkActions';
import { selectTasksBulkHistory } from './tasksBulkSelectors';
import { TasksBulk } from 'api/task/tasksBulk/tasksBulkModel';
import { requestTasksBulkUpload } from 'api/files/filesActions';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import i18n from 'i18n';

function* getSuggestionsReset() {
  yield* takeEvery([OPEN_SIDE_OUT_TASKS_BULK],
      function* ({ payload }: TasksBulkActions) {
        if(!payload) return;

        yield put(resetTaskBulkFile(false));
        yield put(requestTasksBulkHistory.init(''));
      },
  );
}

function* deleteTaskBulkWatcherSaga() {
  yield* takeEvery([requestDeleteTaskBulk.successType],
      function* ({ payload }: ReturnType<typeof requestDeleteTaskBulk.success>) {
        const tasksBulk: TasksBulk = yield* select(selectTasksBulkHistory);
        const removeTaskBulk = tasksBulk.filter((task)=> task.id !== payload.id);

        yield put(requestTasksBulkHistory.success(removeTaskBulk));
      },
  );
}

function* uploadFileTaskBulkWatcherSaga() {
  yield* takeEvery([requestTasksBulkUpload.successType],
      function* () {
        yield put(showToastMessage({
          message: `${i18n.t('File uploaded successfully.')}`, options: { variant: ToastVariant.Success },
        }));
        yield put(resetTaskBulkFile(true));

        yield* delay(1000);
        yield put(requestTasksBulkHistory.init(''));
        yield put(resetTaskBulkFile(false));
      },
  );
}

export const taskBulkSagas = [
  getSuggestionsReset,
  deleteTaskBulkWatcherSaga,
  uploadFileTaskBulkWatcherSaga
];
