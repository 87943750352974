import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { fromEnum } from 'utils/helpers/fromEnum';

export enum LocationTypes {
    HeadQuarters = 'HeadQuarters',
    Region = 'Region',
    District = 'District',
    Store = 'Store',
    Department = 'Department',
}

export const locationSchema = t.type({
    id: t.string,
    name: t.string,
    locationType: fromEnum('LocationTypes', LocationTypes),
    parentLocationId: t.union([t.string, t.null]),
    companyId: t.union([t.string, t.null]),
    completation: t.union([t.undefined, t.null, t.number]),
    progress: t.union([t.undefined, t.null, t.number]),
    overdue: t.union([t.undefined, t.null, t.number]),
    expired: t.union([t.undefined, t.null, t.number]),
    submittedOverdue: t.union([t.undefined, t.null, t.number]),
    completedOverdue: t.union([t.undefined, t.null, t.number]),
});

export const companyLocationsResponseSchema = t.array(locationSchema);

export type Location = t.TypeOf<typeof locationSchema>;

export type CompanyLocationsResponse = t.TypeOf<typeof companyLocationsResponseSchema>;

export interface ILocationType extends Location {
    children: Array<string> | null;
}

const addLocationId = tPromise.createType((param: any): { id: string } => {
    return {
        id: param.locationId,
    };
    },
    () => undefined,
);

const locationTreeBaseSchema = t.intersection([
    addLocationId,
    t.type({
        locationId: t.string,
        name: t.string,
        isStore: t.boolean,
        storesCount: t.number,
        timeZoneId: t.string,
        icon: t.union([t.undefined, t.string]),
    })
]);

export const locationTreeSchema = t.intersection([
    locationTreeBaseSchema,
    t.type({
        children: t.array(locationTreeBaseSchema),
    })
]);

export type LocationTreeResponse = t.TypeOf<typeof locationTreeSchema>;
export type LocationTree = t.TypeOf<typeof locationTreeBaseSchema>;