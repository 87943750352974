import { combineReducers } from 'redux';
import {
    GetStreamActions, GetStreamRoleMembersActions, requestChatMsgSearch, requestChatRoles,
    requestCreateChannel,
    requestCreateDirectMessageChat,
    requestDeleteChannel,
    requestEditChannel,
    requestInviteByRoles,
    requestInviteToChannel,
    requestRemoveChannelMembers, requestRemoveChannelRoles,
} from 'api/getStream/getStreamActions';
import { ItemsById } from '../../../model/entity';
import { ISearchResultMessage } from '../../../api/getStream/getStreamModel';

export type StreamState = {
    isChannelCreating: boolean;
    isChannelUpdating: boolean;
    isDirectMessageChatCreating: boolean;
    isInvitationInProgress: boolean;
    isChannelDeleting: boolean;
    isUserRemovingFromChannel: boolean;
    isMessageSearching: boolean;
    chatRolesList: ItemsById<string[]>;
    chatFoundMessages: ItemsById<ISearchResultMessage>;
};

const initialValues: StreamState = {
    isChannelCreating: false,
    isChannelUpdating: false,
    isDirectMessageChatCreating: false,
    isInvitationInProgress: false,
    isChannelDeleting: false,
    isUserRemovingFromChannel: false,
    isMessageSearching: false,
    chatRolesList: {},
    chatFoundMessages: {},
};

function foundMessagesById(state = initialValues.chatFoundMessages, action: GetStreamActions) {
    switch (action.type) {
        case requestChatMsgSearch.successType:
            const resultMessages = action.payload.results?.map(x => x.message) ?? [];
            const newState = {} as ItemsById<ISearchResultMessage>;
            resultMessages.forEach((x) => {
                newState[x.id] = x;
            });
            return newState;
        case requestChatMsgSearch.initType:
        case requestChatMsgSearch.errorType:
            return initialValues.chatFoundMessages
        default:
            return state;
    }
}

function isMessageSearching(state = initialValues.isMessageSearching, action:GetStreamActions) {
    switch (action.type) {
        case requestChatMsgSearch.initType:
            return true;
        case requestChatMsgSearch.successType:
        case requestChatMsgSearch.errorType:
            return false;
        default:
            return state;
    }
}

function isChannelCreating(state = initialValues.isChannelCreating, action: GetStreamActions) {
    switch (action.type) {
        case requestCreateChannel.initType:
            return true;
        case requestCreateChannel.successType:
        case requestCreateChannel.errorType:
            return false;
        default:
            return state;
    }
}

function isChannelUpdating(state = initialValues.isChannelUpdating, action: GetStreamActions) {
    switch (action.type) {
        case requestEditChannel.initType:
            return true;
        case requestEditChannel.successType:
        case requestEditChannel.errorType:
            return false;
        default:
            return state;
    }
}

function isDirectMessageChatCreating(
    state = initialValues.isDirectMessageChatCreating,
    action: GetStreamActions,
) {
    switch (action.type) {
        case requestCreateDirectMessageChat.initType:
            return true;
        case requestCreateDirectMessageChat.successType:
        case requestCreateDirectMessageChat.errorType:
            return false;
        default:
            return state;
    }
}

function isInvitationInProgress(
    state = initialValues.isInvitationInProgress,
    action: GetStreamActions,
) {
    switch (action.type) {
        case requestInviteToChannel.initType:
        case requestInviteByRoles.initType:
            return true;
        case requestInviteToChannel.successType:
        case requestInviteByRoles.successType:
        case requestInviteToChannel.errorType:
        case requestInviteByRoles.errorType:
            return false;
        default:
            return state;
    }
}

function isChannelDeleting(
    state = initialValues.isChannelDeleting,
    action: GetStreamActions,
): boolean {
    switch (action.type) {
        case requestDeleteChannel.initType:
            return true;
        case requestDeleteChannel.successType:
        case requestDeleteChannel.errorType:
            return false;
        default:
            return state;
    }
}

function isUserRemovingFromChannel(
    state = initialValues.isUserRemovingFromChannel,
    action: GetStreamActions,
): boolean {
    switch (action.type) {
        case requestRemoveChannelMembers.initType:
        case requestRemoveChannelRoles.initType:
            return true;
        case requestRemoveChannelMembers.successType:
        case requestRemoveChannelMembers.errorType:
        case requestRemoveChannelRoles.successType:
        case requestRemoveChannelRoles.errorType:
            return false;
        default:
            return state;
    }
}

function chatRoleIds(
    state = initialValues.chatRolesList,
    action: GetStreamRoleMembersActions,
): ItemsById<string[]> {
    switch (action.type) {
        case requestChatRoles.initType:
            return {
                [action.payload]: [],
            };
        case requestChatRoles.successType:
            return {
                [action.payload.channelId]: action.payload.roles
            };
        default:
            return state;
    }
}

export const getStream = combineReducers({
    isChannelCreating,
    isChannelUpdating,
    isChannelDeleting,
    isUserRemovingFromChannel,
    isDirectMessageChatCreating,
    isInvitationInProgress,
    chatRoleIds,
    isMessageSearching,
    foundMessagesById,
});
