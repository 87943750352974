import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { IResponseErrorPayload } from 'model/error';
import { TasksFilterType, TasksFilterUpdate } from './taskFilterModel';

const REQUEST_TASK_FILTER_GET = 'request/task/filter/GET';
const RESPONSE_TASK_FILTER_GET_SUCCESS = 'response/task/filter/GET_SUCCESS';
const RESPONSE_TASK_FILTER_GET_ERROR = 'response/task/filter/GET_ERROR';

export const getDefaultTaskFilter = createApiActions<{isStore: boolean, useCanAccessTasks: boolean }, Partial<TasksFilterType>, IResponseErrorPayload, typeof REQUEST_TASK_FILTER_GET,
    typeof RESPONSE_TASK_FILTER_GET_SUCCESS, typeof RESPONSE_TASK_FILTER_GET_ERROR
>(
    REQUEST_TASK_FILTER_GET, RESPONSE_TASK_FILTER_GET_SUCCESS, RESPONSE_TASK_FILTER_GET_ERROR,
);

const REQUEST_TASK_FILTER_UPDATE = 'request/task/filter/UPDATE';
const RESPONSE_TASK_FILTER_UPDATE_SUCCESS = 'response/task/UPDATE/UPDATE_SUCCESS';
const RESPONSE_TASK_FILTER_UPDATE_ERROR = 'response/task/UPDATE/UPDATE_ERROR';

export const requestUpdateTaskFilter = (
    createApiActions<TasksFilterUpdate, Partial<TasksFilterType>, IResponseErrorPayload,
        typeof REQUEST_TASK_FILTER_UPDATE,
        typeof RESPONSE_TASK_FILTER_UPDATE_SUCCESS,
        typeof RESPONSE_TASK_FILTER_UPDATE_ERROR
    >(
        REQUEST_TASK_FILTER_UPDATE,
        RESPONSE_TASK_FILTER_UPDATE_SUCCESS,
        RESPONSE_TASK_FILTER_UPDATE_ERROR,
    )
);

export type TaskFilterActions = (
    | ActionsReturnTypes<typeof getDefaultTaskFilter>
    | ActionsReturnTypes<typeof requestUpdateTaskFilter>
);
