import React, { useCallback, useMemo, useState } from 'react';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { SimpleTaskListHeader } from '../../components/SimpleTaskListHeader';
import { TaskListPageLayout } from '../../components/TaskListPageLayout';
import { useFixedSizeListHeight } from '../../hooks/useFixedSizeListHeight';
import { useCalendarTasksData } from './calendar/useCalendarTasksData';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { makeStyles, Theme } from '@material-ui/core';
import { allTasksSyntheticValue, getAutomationTaskFilterOptions } from '../../components/model';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { FlexBox } from 'modules/shared/components/FlexBox';
import { TaskTaglistMultiselect } from 'modules/shared/components/TaskTaglistMultiselect';
import { ViewSwitch } from '../../components/ViewSwitch/ViewSwitch';
import { ButtonSwitchType } from '../../components/ViewSwitch/TypeViewSwithMap';
import { CalendarTasks } from './calendar/CalendarTasks';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { TaskByLocationsSideOut } from './TaskByLocationsSideOut';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { LocationFilter } from './TaskLocation/LocationFilter';
import { CreateEventButton } from '../../components/CreateEventButton';
import { RemoveCalendarEventConfimation } from 'modules/task/taskCalendar/RemoveCalendarEventConfirmation';
import { EventSideOut } from 'modules/task/taskCalendar/eventSideOut/EventSideOut';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { useActions } from 'utils/store/useActions';
import { calendarFilter } from 'store/modules/task/calendarTask/calendarTaskActions';

export type StoreTasksViewParams = {
    locationId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    statusFilter: {
        width: '100%',
        maxWidth: '205px',
        display: 'flex',
        justifyContent: 'center'
    },
    select: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        lineHeight: '24px',
    },
}));

export const CalendarTasksView = ({ locationId }: StoreTasksViewParams) => {
    const classes = useStyles();
    const data = useCalendarTasksData();
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const { isCompanyLocationStore = true } = useCurrentUser() || {};
    const location = useSelector(selectLocationsByIds)[locationId];
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();
    const canAccessTask = useSelector(selectHasAppAccess(AppName.Tasks));
    const typeTasksAppAccess = canAccessTask ? ButtonSwitchType.ParentTasks : ButtonSwitchType.NoAccessTaskPage;
    const [valuesFiltersTasks, setValuesFiltersTasks ] = useState<{filter?: AutomationTaskStatusFilter, tags: string[]}>({filter: undefined, tags:[]});
    const options = useMemo(() => (getAutomationTaskFilterOptions()), []);
    const actions = useActions({ calendarFilter });

    const handleFilterTag = useCallback((newValues: string[]) => {
        if(JSON.stringify(valuesFiltersTasks.tags) !== JSON.stringify(newValues)){
            setValuesFiltersTasks((prev)=>({...prev, tags: newValues }));
            actions.current.calendarFilter({filter: valuesFiltersTasks.filter, tags: newValues })
        }
    },[valuesFiltersTasks, actions])
    const handleSetFilterStatus = useCallback((newValue?: AutomationTaskStatusFilter) => {
        setValuesFiltersTasks((prev)=>({...prev, filter: newValue }))
        actions.current.calendarFilter({tags: valuesFiltersTasks.tags, filter: newValue })
    },[valuesFiltersTasks, actions])

    const header = useMemo(() => {
        if (isCompanyLocationStore) {
            return (
                <FlexBox flexDirection='row' flexWrap="wrap">
                    <SimpleTaskListHeader regionName={location?.name} />
                    <ViewSwitch type={typeTasksAppAccess} />
                    <OptionsDropdown
                        className={classes.statusFilter}
                        selectClasses={{ select: classes.select }}
                        unsetValue={allTasksSyntheticValue}
                        options={options}
                        value={valuesFiltersTasks.filter}
                        onValueChange={handleSetFilterStatus}
                    />
                    {(isTagEnabled && canAccessTask) && <TaskTaglistMultiselect 
                        onTagsValuesChange={handleFilterTag}
                        initialTagSelected={valuesFiltersTasks.tags}
                    />}
                </FlexBox>
            )
        }else{
            return (
                <FlexBox flexDirection='column' flexWrap="wrap">
                    <FlexBox flexWrap="wrap" width={{xs: '100%'}}>
                        <SimpleTaskListHeader regionName={location?.name} />
                    </FlexBox>
                    <FlexBox flexWrap="wrap" width={{xs: '100%'}}>
                        <ViewSwitch type={ButtonSwitchType.ParentTasks} />
                        <OptionsDropdown
                            className={classes.statusFilter}
                            selectClasses={{ select: classes.select }}
                            unsetValue={allTasksSyntheticValue}
                            options={options}
                            value={valuesFiltersTasks.filter}
                            onValueChange={handleSetFilterStatus}
                        />
                        {(isTagEnabled && canAccessTask) && <TaskTaglistMultiselect 
                            onTagsValuesChange={handleFilterTag}
                            initialTagSelected={valuesFiltersTasks.tags}
                        />}
                        {canAccessTask &&
                            <LocationFilter />
                        }
                    </FlexBox>
                </FlexBox>
            )
        }
    }, [classes, handleSetFilterStatus, options, handleFilterTag, valuesFiltersTasks, location, isCompanyLocationStore, typeTasksAppAccess, canAccessTask, isTagEnabled]);

    return (
        <>
            <TaskListPageLayout
                header={header}
                layoutHeaderRef={layoutHeaderRef}
                showCreateButton={canCreateTask}
                createButton={<CreateEventButton/>}
            >
                <CalendarTasks 
                    data={data}
                    height={gridHeight}
                />
                <StoreTaskView />
                <TaskByLocationsSideOut />
                <EventSideOut />
            </TaskListPageLayout>
            <RemoveCalendarEventConfimation />
        </>
    );
};
