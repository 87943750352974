import React, { useCallback } from 'react';
import { Button, styled } from '@material-ui/core';
import { taskReviewModalOpen } from 'store/modules/task/taskReview/taskReviewActions';
import { useActions } from "utils/store/useActions";

import { useSelector } from 'react-redux';
import { selectTaskReviewCounter } from 'store/modules/task/taskReview/taskReviewSelectors';
import { UtilColors } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const StyledButton = styled(Button)({
    color: UtilColors.Red2,
    paddingTop: 0,
    paddingBottom: 0,
});

const TasksToReviewButton: React.FC = () => {
    const counter = useSelector(selectTaskReviewCounter);
    const actions = useActions({
        openModal: taskReviewModalOpen,
    });

    const onClick = useCallback(() => {
        actions.current.openModal();
    }, [actions]);

    return (
        <StyledButton
            onClick={onClick}
            disabled={!counter}
        >
            {counter} <Trans>Tasks To Review</Trans>
        </StyledButton>
    );
};

export default TasksToReviewButton;
