import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { GroupAdd as GroupAddIcon } from '@material-ui/icons';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { BlueColor } from 'utils/styles/constants';
import { InviteActionContainer } from './InviteActionContainer';
import { useChatContext } from 'stream-chat-react';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';

const useStyles = makeStyles(() => ({
    actionIcon: {
        color: BlueColor.Blue70,
    },
}));

export function InviteAction() {
    const { channel, client } = useChatContext();
    const { isOpened, onOpen, onClose } = useOpenedState();
    const classes = useStyles();
    const canInvite = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));

    if (!canInvite) {
        return null;
    }

    if (!channel) {
        return null;
    }

    return (
        <>
            <IconButton onClick={onOpen}>
                <GroupAddIcon className={classes.actionIcon}/>
            </IconButton>

            <InviteActionContainer
                isDialogOpened={isOpened}
                onDialogClose={onClose}
                channel={channel}
                client={client}
            />
        </>
    );
}
