import { useEffect } from "react";
import { CellProps } from "react-table";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { requestTasksStatistics } from "store/modules/task/statistics/statisticsActions";
import { useActions } from "utils/store/useActions";
import { Location } from "api/hierarchy/hierarchyModel";
import { useSelector } from "react-redux";
import {
    selectTasksStatsByLocationIds,
    selectTasksStatsByLocationRangeDate,
    selectTasksStatsByLocationTags,
    selectTasksStatsLoadingByLocationIds,
} from 'store/modules/task/statistics/statisticsSelectors';
import { TaskCompletion } from './TaskCompletion';
import { TaskCounterCellProps } from "./TaskCounterCell";

export type TaskCompletionCellProps = CellProps<Location, string>;

export const TaskCompletionCell = (props: TaskCounterCellProps) => {
    const locationId = props.row.values.locationId;
    const tasksStatistics = useSelector(selectTasksStatsByLocationIds)[locationId];
    const isTasksStatisticsLoading = useSelector(selectTasksStatsLoadingByLocationIds)[locationId];
    const rangeDate = useSelector(selectTasksStatsByLocationRangeDate);
    const tags = useSelector(selectTasksStatsByLocationTags);
    const actions = useActions({ requestStats: requestTasksStatistics });
    
    useEffect(() => {
        actions.current.requestStats({locationId, ...rangeDate, tags });
    }, [actions, locationId, rangeDate, tags]);

    if (isTasksStatisticsLoading || !tasksStatistics) {
        return (
            <Box>
                <Skeleton width="60%" />
                <Skeleton />
            </Box>
        )
    }

    return (
        <TaskCompletion stats={tasksStatistics} />
    );
};
