import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';
import { Counter } from '../../../Counter';
import { DeleteConversationButton } from '../conversationsPreviewButtons/DeleteConversationButton';

const TOOLTIP_DELAY_MS = 500;

const useStyles = makeStyles(() => ({
    channelPreviewRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '12px 40px',
        boxSizing: 'border-box',
        backgroundColor: UtilColors.Transparent,
        transition: 'background-color 0.3s',
        position: 'relative',
    },
    channelSelected: {
        backgroundColor: BlueColor.Pale,
    },
    title: {
        maxWidth: '95%',
        lineHeight: '17px',
        fontWeight: 500,
        color: GrayColor.GrayBlue,
        wordBreak: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    titleUnread: {
        fontWeight: 700,
        color: GrayColor.GrayBlack,
    },
}));

export interface IChannelPreviewProps {
    id: string,
    title: string;
    unreadCound: number;
    isSelected: boolean;
    onSelect: () => void;
}

export function ChannelPreview({
    id,
    title,
    unreadCound,
    isSelected,
    onSelect,
}: IChannelPreviewProps) {
    const classes = useStyles();
    const wrapperClass = clsx(classes.channelPreviewRoot, {
        [classes.channelSelected]: isSelected,
    });
    const titleClass = clsx(classes.title, {
        [classes.titleUnread]: unreadCound > 0,
    });
    return (
        <Box className={wrapperClass} onClick={onSelect}>
            <Tooltip
                title={title}
                placement="top"
                enterDelay={TOOLTIP_DELAY_MS}
                enterNextDelay={TOOLTIP_DELAY_MS}
            >
                <Typography
                    className={titleClass}
                    variant="body2"
                >
                    # {title}
                </Typography>
            </Tooltip>
            { isSelected && (
                <DeleteConversationButton
                    conversationId={id}
                />
            )}
            { !isSelected && <Counter count={unreadCound}/> }
        </Box>
    );
}
