import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { AVATAR_SIZE, StyledBox, StyledInfoWrapper } from "./styles";

export type UserInfoSkeletonProps = {
    className?: string;
};

export const UserInfoSkeleton = ({ className }: UserInfoSkeletonProps) => {
    return (
        <StyledBox className={className}>
            <Skeleton
                variant="circle"
                width={AVATAR_SIZE}
                height={AVATAR_SIZE}
            />
            <StyledInfoWrapper>
                <Skeleton width="95px" />
                <Skeleton width="50px" />
            </StyledInfoWrapper>
        </StyledBox>
    );
};
