import { Box, Theme, makeStyles, useMediaQuery } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import { useMemo } from "react";
import { locationFilterSchema } from "./model";
import { useSelector } from "react-redux";
import { selectLocationsTree } from "store/modules/hierarchy/hierarchySelectors";
import { MultiSelect } from "components/basicInputs/selectInput/MultiSelect";
import { t } from "i18next";
import { LocationItem } from "modules/users/user-list/LocationItem";
import { LocationTree } from '../../../../../../api/hierarchy/hierarchyModel';
import { Breakpoints } from "utils/styles/constants";
import { selectCalendarTasksFetching } from "store/modules/task/calendarTask/calendarSelectors";
import { useActions } from "utils/store/useActions";
import { calendarComponentLocationIdsSelected } from "store/modules/task/calendarTask/calendarTaskActions";

const useLocationStyles = makeStyles((theme: Theme) => ({
    topEnvelope: {
        width: '100%',
        maxWidth: 240,
        minWidth: 240,
    },
    topEnvelopeMobile: {
        width: '100%',
    },
}));

const LocationForm = ({ handleSubmit }: FormikProps<any>) => {
    const locations: LocationTree[] = useSelector(selectLocationsTree);
    const isselectCalendarTasksFetching = useSelector(selectCalendarTasksFetching);

    return(
        <form onSubmit={handleSubmit}>
            <MultiSelect
                name={'locations'}
                label={`${t('Location')}`}
                placeholder={`${t('Location')}`}
                id="locationId"
                options={locations}
                getName={(x) => { return x.name; }}
                RenderPreview={LocationItem}
                RenderValue={LocationItem}
                showValuesSelected={false}
                separateLabel={false}
                disabled={isselectCalendarTasksFetching}
            />
        </form>
    )
};

export const LocationFilter = () => {
    const classes = useLocationStyles([]);
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.Tablet),
        { noSsr: true },
    );
    const topEnvelopeStyle = useMemo(() => {
        return isMobile ? classes.topEnvelopeMobile : classes.topEnvelope;
    }, [isMobile, classes]);
    const initialFormValues = useMemo(
        () => ({
            locations: []
        }),
        []
    );
    const actions = useActions({ 
        locationIdsSelected: calendarComponentLocationIdsSelected,
    });
    return (
        <Box className={topEnvelopeStyle} ml={.5} mr={0.5}>
            <Formik
                initialValues={initialFormValues}
                validationSchema={locationFilterSchema}
                onSubmit={()=>{}}
                validateOnChange={true}
                validate={(values) => {
                    actions.current.locationIdsSelected(values.locations)
                }}
                validateOnBlur={false}
            >
                {(props) => <LocationForm {...props} />}
            </Formik>
        </Box>
    );
};

