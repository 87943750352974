import React, { Suspense, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { t } from 'i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { Box } from '@material-ui/core';
import { useConversationStyles } from "./conversationStyles";
import { FormikHandlers, useField } from 'formik';
import { CustomToolbar } from './CustomToolbar';
import { TextConversationEditorActions } from './TextConversationEditorActions';
import { DropzoneTextEditor } from './DropZoneTextEditor';
import { useDropzone } from 'react-dropzone';
import { useDropHandler } from 'store/modules/files/filesHooks';
import { addPendingConversationFiles } from 'store/modules/files/filesActions';
import { useSelector } from 'react-redux';
import { selectSuggestions } from 'store/modules/task/taskConversation/taskConversationSelectors';
import { LinkBlot } from './moduleLink';

type TextConversationEditorProps = {
    placeholder?: string,
    onCancel: ()=> void,
    isUpdating: boolean,
    handleSubmit: FormikHandlers['handleSubmit']
}
const FiledName = 'text';

Quill.register("formats/link", LinkBlot);

const RichTextConversationEditor: React.FC<TextConversationEditorProps> = ({
    placeholder = `${t('Type your message')}`, onCancel, isUpdating, handleSubmit
}) => {
    const suggestions = useSelector(selectSuggestions);
    const editorRef = useRef<ReactQuill | null>(null);
    const classes = useConversationStyles();
    const [field] = useField(FiledName);
    const { onChange, value } = field;
    const { getInputProps, inputRef } = useDropzone({ onDrop: useDropHandler(()=>{},addPendingConversationFiles), noClick: true,noKeyboard: true, });
    const suggestionsMemo = useMemo(()=> suggestions.map((user)=>({id: user.id, value: `${user.firstName} ${user.lastName}`})),[suggestions])

    useLayoutEffect(() => {
        focusEditor();
    },[])

    const focusEditor = (position = 0) => {
        setTimeout(() => {
            if (editorRef.current) {
                const quill = editorRef.current.getEditor();
                quill.setSelection(position, 0);
                editorRef.current.focus();
            }
        }, 0);
    };

    const handleChange = useCallback((html: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
        onChange({ target: { name: FiledName, value: html } });
    },[onChange]);

    const insertImage = useCallback(()=> {
        inputRef.current?.click();
    },[inputRef]);

    const EditorModules = {
        toolbar: {
            container: "#toolbar",
            handlers: {
                uploadFile: insertImage,
            }
        },
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            spaceAfterInsert: true,
            source: useCallback((searchTerm, renderList, mentionChar) => {
                let values: any = [];
                if (mentionChar === "@") {
                    values = suggestionsMemo;
                }
                if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                } else {
                    const matches = [];
                    for (const val of values) {
                        if (
                            ~val.value.toLowerCase().indexOf(searchTerm.toLowerCase())
                        ){
                            matches.push(val);
                        }
                    }
                    renderList(matches, searchTerm);
                }
            }, [suggestionsMemo]),
        },
    }

    return (
        <Box width="100%">
            <form onSubmit={handleSubmit} data-testid="taskConversationForm" id="textEditor">
                <Suspense fallback={null}> 
                    <DropzoneTextEditor >
                        <input {...getInputProps()} />
                        <CustomToolbar />
                        <ReactQuill
                            theme="snow"
                            value={value}
                            ref={editorRef}
                            onChange={handleChange}
                            placeholder={placeholder}
                            modules={EditorModules}
                            formats={EditorFormats}
                            preserveWhitespace
                            className={`${classes.toolbar}`}
                            readOnly={isUpdating}
                            bounds={'#textEditor'}
                        />
                    </DropzoneTextEditor>
                </Suspense>
                <TextConversationEditorActions isInProgress={isUpdating} onClickCancel={onCancel}/>
            </form>
        </Box>
    )
}

const EditorFormats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'mention',
    'color',
    'align'
];

export const TextConversationEditor = React.memo(RichTextConversationEditor);
