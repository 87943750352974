import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Fade, Typography } from '@material-ui/core';
import { BaseEmoji, Emoji } from 'emoji-mart';
import { useConversationStyles } from './conversationStyles';
import 'emoji-mart/css/emoji-mart.css';
import clsx from 'clsx';
import { useActions } from 'utils/store/useActions';
import { requestAddCommentReaction, requestDeleteCommentReaction } from 'api/task/taskConversation/taskConversationActions';
import { ConversationType, ReactionEnum } from 'api/task/taskConversation/taskConversationModel';
import _ from 'lodash';

enum ReactionEmojiEnum {
    smiley = 'smiley',
    white_check_mark = 'white_check_mark',
    heart = 'heart',
    thumbsup = 'thumbsup',
}

const ReactionTypeRecords = {
    [ReactionEnum.Smile]: ReactionEmojiEnum.smiley,
    [ReactionEnum.Check]: ReactionEmojiEnum.white_check_mark,
    [ReactionEnum.Like]: ReactionEmojiEnum.heart,
    [ReactionEnum.Thumbsup]: ReactionEmojiEnum.thumbsup,
    [ReactionEmojiEnum.smiley]: ReactionEnum.Smile,
    [ReactionEmojiEnum.white_check_mark]: ReactionEnum.Check,
    [ReactionEmojiEnum.heart]: ReactionEnum.Like,
    [ReactionEmojiEnum.thumbsup]: ReactionEnum.Thumbsup,
    '+1': ReactionEnum.Thumbsup,
}

type ReactionCommentProps = {
    conversation: ConversationType;
    storeTaskId: string;
}
export const ReactionComment: React.FC<ReactionCommentProps> = ({ conversation, storeTaskId }) => {
    const classes = useConversationStyles();
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const actions = useActions({
        addReaction: requestAddCommentReaction.init,
    });

    const handleSelectEmoji = useCallback((emoji: BaseEmoji)=> {
        actions.current.addReaction({storeTaskId, commentId: conversation.id, reactionType: ReactionTypeRecords[emoji.id as ReactionEmojiEnum]})
        setShowPicker(!showPicker);
    },[showPicker, storeTaskId, actions, conversation.id])

    return (
        <Box position="relative" alignItems="center" display="flex" style={{gap: 4}}>
            <Reactions conversation={conversation} storeTaskId={storeTaskId}/>
            <Box position="relative" alignItems="center" display="flex">
                <Button onClick={()=>setShowPicker(!showPicker)} color='secondary' size='small'
                    className={`${classes.actionsButtonComment} ${showPicker ? classes.btnEmojiActive : ''}`}
                    variant='outlined'
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
                        <path d="M8.049 14.197c-.166-.476.103-.991.602-1.15.5-.159 1.039.098 1.205.575.06.174.225.487.495.796.426.488.955.764 1.649.764.693 0 1.223-.276 1.65-.764.27-.31.433-.622.494-.796.166-.476.706-.734 1.204-.575.5.159.77.673.603 1.15-.13.371-.395.876-.839 1.385C14.348 16.458 13.306 17 12 17s-2.348-.542-3.112-1.418a4.382 4.382 0 01-.839-1.385zM9.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM19 5h1a1 1 0 010 2h-1v1a1 1 0 01-2 0V7h-1a1 1 0 010-2h1V4a1 1 0 012 0v1zm-5-1.777V5.29A7 7 0 1018.71 10h2.067A9 9 0 1114 3.223z" fill="currentColor">
                        </path>
                    </svg>
                </Button>
                <Fade in={showPicker} timeout={300}>
                    <Box className={classes.emojiContainer}>
                        <Box className={classes.actionsButtonComment} display="flex" justifyContent="center">
                            <Emoji emoji='smiley' set='apple' size={20} onClick={handleSelectEmoji} tooltip/>
                        </Box>
                        <Box className={classes.actionsButtonComment} display="flex" justifyContent="center">
                            <Emoji emoji='white_check_mark' size={20}  onClick={handleSelectEmoji} tooltip/>
                        </Box>
                        <Box className={classes.actionsButtonComment} display="flex" justifyContent="center">
                            <Emoji emoji='heart' set='apple' size={20} onClick={handleSelectEmoji} tooltip/>
                        </Box>
                        <Box className={classes.actionsButtonComment} display="flex" justifyContent="center">
                            <Emoji emoji='thumbsup' set='apple' size={20} onClick={handleSelectEmoji} tooltip/>
                        </Box>
                    </Box>
                </Fade>
            </Box>
        </Box>
    )
}

const Reactions: React.FC<ReactionCommentProps> = ({ conversation, storeTaskId }) => {
    const classes = useConversationStyles();
    const reactionsCount = useMemo(() => _.countBy(conversation.reactions, 'reactionType'), [conversation.reactions]);
    const groupedReactions = useMemo(() => _.groupBy(conversation.reactions, 'reactionType'), [conversation.reactions]);
    const actions = useActions({ deleteReaction: requestDeleteCommentReaction.init });

    const handleRemoveReaction = useCallback((reactionKey) => {
        const findComment = groupedReactions[reactionKey].find(reaction=>reaction.canEdit);
        if(findComment){
            actions.current.deleteReaction({storeTaskId, reactionId: findComment.id })
        }
    }, [storeTaskId, actions, groupedReactions])

    return (
        <>
            {
                Object.keys(groupedReactions).map((reactionKey) => (
                    <Box key={reactionKey}>
                        <Box color='primary'
                            className={clsx(classes.actionsButtonComment, classes.btnReactions)}
                            onClick={() => handleRemoveReaction(reactionKey)}
                        >
                            <Emoji
                                set={'apple'}
                                emoji={ReactionTypeRecords[reactionKey as ReactionEnum]}
                                size={16}
                            />
                            <Typography>{reactionsCount[reactionKey]}</Typography>
                        </Box>
                    </Box>
                ))
            }
        </>
    )
}