import React, { useCallback, useState, useEffect } from 'react';
import { BaseInput, IBaseInputProps } from 'components/basicInputs/BaseInput';
import { IInputProps } from 'components/basicInputs/model';
import { useField } from 'formik';
import RichTextEditor, { EditorValue } from 'react-rte';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { fontFamily } from 'modules/root/theme';

const format = 'markdown';

const useMarkdownInputStyles = makeStyles((theme: Theme) => ({
    editor: {
        fontFamily,
        '& .DraftEditor-editorContainer': {
            '&, & > *': {
                minHeight: theme.spacing(15),
            },
        },
    },
    toolbarTextEditor:{
        '& div[class*="ButtonGroup"]': {
            '&:nth-child(3)':{
                '& div[class*="InputPopover__root"]':{
                    '& div[class*="InputPopover__checkOption"]':{
                    display: 'none'
                    }
                }
            }
        }
    }
}));

type RichTextEditorProps = React.ComponentProps<typeof RichTextEditor>;

const toolbarConfig: RichTextEditorProps['toolbarConfig'] = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Monospace', style: 'CODE' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
        { label: 'Blockquote', style: 'blockquote' },
    ],
};

interface IMarkdownInputProps extends IInputProps, Pick<IBaseInputProps, 'classes'> {
    disabled?: boolean;
}

export function MarkdownInput({ name, id = name, disabled, ...baseInputProps }: IMarkdownInputProps) {
    const classes = useMarkdownInputStyles([]);
    const [field] = useField(name);
    const { value: propsValue, onChange } = field;
    const [value, setValue] = useState(RichTextEditor.createValueFromString(propsValue, format));

    const onValueChange = useCallback((newValue: EditorValue) => {
        setValue(newValue);
        onChange({ target: { name, value: newValue.toString(format) } });
    }, [onChange, name]);

    useEffect(()=>{
        if(value.toString(format)?.length !== propsValue?.length){
            const newValue = RichTextEditor.createValueFromString(propsValue, format)
            setValue(newValue);
        }
    },[propsValue, value, onValueChange])

    return (
        <BaseInput id={id} {...baseInputProps}>
            <RichTextEditor
                disabled={disabled}
                value={value}
                onChange={onValueChange}
                toolbarConfig={toolbarConfig}
                editorClassName={classes.editor}
                toolbarClassName={classes.toolbarTextEditor}
            />
        </BaseInput>
    );
}
