import React, { useEffect, useMemo } from 'react';
import {
    useFlexLayout,
    useSortBy,
    useTable,
} from 'react-table';
import { IParentTaskGridRowData } from './model';
import { taskListInitialSortBy, useColumns } from './columns';
import { useLoad } from 'modules/task/taskList/hooks/useLoad';
import { useActions } from "utils/store/useActions";
import { parentTaskListSetSorting, taskListResetData } from 'store/modules/task/taskListComponent/taskListComponentActions';
import { makeRowRenderer, makeSkeletons } from 'modules/shared/components/gridHelpers';
import { taskListCheckRequest } from 'api/task/taskActions';
import { selectParenttasksNextPageToken, selectParentTasksFetching } from 'store/modules/task/parentTask/parentTaskSelectors';
import { ParentTaskSearchRequest, ParentTaskStatusFilter } from 'api/task/parentTask/parentTaskModel';
import { selectParentTasksSortBy } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { useSelector } from 'react-redux';
import { VirtualScrollGrid } from 'modules/shared/components/VirtualScrollGrid';
import { EmptyState } from 'modules/task/taskList/components/EmptyState';
import { DefaultSortByParentTask } from 'api/task/taskFilter/taskFilterModel';
import { t } from 'i18next';

export interface IParentTasksGridProps {
    data: Array<IParentTaskGridRowData>;
    filter?: ParentTaskStatusFilter;
    filterTag?: Array<string>;
    searchTerm?: string;
    isFetching: boolean;
    height?: number;
    tasksViewMode?: string;
}

const skeletons = makeSkeletons<IParentTaskGridRowData>(20);

export const ParentTasksGrid: React.FC<IParentTasksGridProps> = ({ data, filter, filterTag, searchTerm, isFetching, height = 500, tasksViewMode }) => {
    const baseSearchRequest: ParentTaskSearchRequest = useMemo(() => ({ filter, tags: filterTag, searchTerm, tasksViewMode }), [filter, filterTag, searchTerm, tasksViewMode]);
    const parentTasksSortBy = useSelector(selectParentTasksSortBy);
    const { preload, nextPageToken } = useLoad(
        selectParenttasksNextPageToken,
        selectParentTasksFetching,
        taskListCheckRequest,
        taskListResetData,
        baseSearchRequest,
        parentTasksSortBy,
    );
    const columns = useColumns();
    const actions = useActions({ setSorting: parentTaskListSetSorting });
    const showEmptyState = data.length === 0 && !isFetching;
    const showSkeletons = data.length === 0 && isFetching;
    const totalData = showSkeletons ? skeletons : data;
    const dataGrid = useTable<IParentTaskGridRowData>(
        {
            columns,
            data: totalData,
            manualSortBy: true,
            disableMultiSort: true,
            initialState: {sortBy: [ {
                id: parentTasksSortBy !== 'not-set' ? parentTasksSortBy?.id as string : DefaultSortByParentTask,
                desc: parentTasksSortBy !== 'not-set' ? parentTasksSortBy?.desc : false,
            }]},
        },
        useFlexLayout,
        useSortBy,
    );

    const { state: { sortBy: sortByArray } } = dataGrid;
    useEffect(() => {
        const sortBy = sortByArray.length > 0 ? sortByArray[0] : null;
        actions.current.setSorting(sortBy || taskListInitialSortBy);
    }, [actions, sortByArray]);

    return (
        <VirtualScrollGrid
            dataGrid={dataGrid}
            showEmptyState={showEmptyState}
            emptyState={<EmptyState text={`${t('Please check your search, or adjust task filters as necessary')}.`}/>}
            height={height}
            nextPageToken={nextPageToken}
            preload={preload}
            customRowRenderer={makeRowRenderer}
        />
    );
};
