import { makeStyles, Theme } from '@material-ui/core/styles';
import { BlueColor, UtilColors } from 'utils/styles/constants';

export const useSelectStyles = makeStyles((theme: Theme) => ({
    menuPaper: {
        minWidth: theme.spacing(30),
        marginTop: theme.spacing(0.5),
        border: `2px solid ${BlueColor.Blue30}`,
        borderRadius: theme.spacing(1),
    },
    menuList: {
        padding: 0,

        '&& > li': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(0, 1),
            '& + *': {
                borderTop: `1px solid ${BlueColor.Blue30}`,
            },

            '&&.Mui-selected': {
                backgroundColor: UtilColors.White,
            },
        },
    },
    radio: {
        padding: theme.spacing(1),
    },
    selectPreview: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: BlueColor.Blue70,
        margin: theme.spacing(0.5),
    },
    selectChip: {
        backgroundColor: BlueColor.Blue5,
        borderRadius: theme.spacing(3),
        display: 'flex',
        padding: theme.spacing(0.5, 1),
        marginRight: theme.spacing(0.5),
    },
    removeIcon: {
        color: BlueColor.Blue70,
    },
    // Do not remove this class, it's created to override Autocomplete selector priority
    autocompleteInput: {},
    autocompleteInputRoot: {
        '&& input$autocompleteInput': {
            padding: theme.spacing(1, 1.5),
        },
    },
    autocompleteRoot: {
        '&& $autocompleteInputRoot': {
            padding: theme.spacing(0, 0, 0, 1),
        },
    },
    selectAll: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
    },
    multiselectPaperWrapper: {
        '&&': {
            overflowY: 'auto',
        },

        '& ul': {
            overflow: 'initial',
        },
    },
    selectChipAvatars: {
        display: 'flex',
        padding: theme.spacing(0, 0),
        marginLeft: '-10px'
    },
}));
