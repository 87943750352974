import React, { useCallback, useMemo } from 'react';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { FormikHandlers, useField } from 'formik';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { MarkdownInput } from 'components/basicInputs/MarkdownInput';
import { TASK_DATE_FORMAT, TASK_MAX_TITLE_LENGTH } from 'modules/task/common/form/validation';
import { DateInput } from 'components/basicInputs/dateInput/DateInput';
import { AttachmentsEdit } from '../attachments/Attachments/AttachmentsEdit';
import { TaskLocationsView } from '../locations/TaskLocationsView';
import { TaskChecklist } from '../common/checklist/TaskChecklist';
import { FormActions } from 'modules/shared/components/FormActions';
import { WatchersDropdown } from '../common/watchers/WatchersDropdown';
import { useFeatureState } from '../../../utils/hooks/useFeatureState';
import { FeatureFlags } from '../../../utils/featureFlags';
import { LabelsDropdown } from '../common/labels/LabelsDropdown';
import { RecurrenceDropdown } from '../common/recurrence/RecurrenceDropdown';
import { ToggleExpiredDate } from './ToggleExpiredDate';
import { TaskTaglist } from '../common/taglist/TaskTaglist';
import { t } from 'i18next';
import { IChecklistItem } from 'api/task/taskModel';
import { Trans } from 'react-i18next';
import { TitleForm } from '../common/titleForm';
import { Switch as SwitchCustom } from 'components/switch/Switch';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Breakpoints } from 'utils/styles/constants';
import SaveIcon from '@material-ui/icons/Save';
import { useActions } from 'utils/store/useActions';
import { setEditTaskType } from 'store/modules/task/editTask/editTaskActions';
import { EditTaskType } from 'store/modules/task/editTask/editTaskModel';
import { TaskLocationsCreate } from '../locations/TaskLocationsCreate';

export type EditTaskFormProps = {
    isUpdating: boolean;
    handleSubmit: FormikHandlers['handleSubmit']
    isClone: boolean;
};

const isExpiredDateFieldName: string = 'isExpirable';
const createdByFieldName: string = 'createdBy';
const checkistFieldName: string = 'checklistItems';

export function EditTaskForm({ isUpdating, handleSubmit, isClone }: EditTaskFormProps) {
    const classes = useFormStyles([]);
    const isWatchersEnabled = useFeatureState(FeatureFlags.TaskWatchers);
    const isLabelEnabled = useFeatureState(FeatureFlags.TaskLabel);
    const isExpirableEnabled = useFeatureState(FeatureFlags.TaksExpirable);
    const isPublishDateEnabled = useFeatureState(FeatureFlags.TaskCreatePublishDate);
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const isNtfTaskDueReminderEnabled = useFeatureState(FeatureFlags.NtfTaskDueReminder);
    const isTasksTemplateEnabled = useFeatureState(FeatureFlags.TasksTemplate);
    const [fieldcreatedBy] = useField(createdByFieldName);
    const { value: valueCreatedBy } = fieldcreatedBy;
    const [fieldIsExpiredDate] = useField(isExpiredDateFieldName);
    const [fieldChecklistItems] = useField(checkistFieldName);
    const { value: valueIsExpirable, onChange } = fieldIsExpiredDate;
    const actions = useActions({
        setEditTaskType
    });

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked, name } } = event;
        onChange({
            target: {
                name: name,
                value: checked,
            }
        })
    }, [onChange]);

    const hideToggleExpiredDate = useMemo(() => ((!isClone && !valueIsExpirable) || !isExpirableEnabled), [isClone, valueIsExpirable, isExpirableEnabled])

    const title = isClone ? <Trans>Clone Task</Trans> : <Trans>Edit Task</Trans>;
    const isApproval = useMemo(() => (
        fieldChecklistItems.value.some((item: IChecklistItem) => item.requiresAttachment || item.requiresCustomResponse)
    ), [fieldChecklistItems]);
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const saveAsTemplateBtn = useMemo(() => {
        return isMobile ? <SaveIcon /> : <Trans>Save as New Template</Trans>;
    }, [isMobile]);
    const handleSubmitNewTemplate = useCallback(() => {
        actions.current.setEditTaskType(EditTaskType.CreateTemplate);
        handleSubmit();
    }, [handleSubmit, actions]);

    const handleSubmitForm = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const editTaskType = isClone ? EditTaskType.CloneTask : EditTaskType.EditTask;
        actions.current.setEditTaskType(editTaskType);
        handleSubmit();
    }, [handleSubmit, isClone, actions]);

    return (
        <form onSubmit={handleSubmitForm}>
            <TitleForm
                title={title}
                showHourGlass={valueIsExpirable}
                tagList={isTagEnabled && <TaskTaglist nameField="tag" />}
                isApproval={isApproval}
            />
            {isLabelEnabled && (
                <Box className={classes.labelsDropdown}>
                    <LabelsDropdown
                        name="label"
                        label={`${t('Label')}`}
                    />
                </Box>)}
            <Box className={classes.formInputs}>
                <TextInput
                    classes={{ root: classes.titleInput }}
                    name="title"
                    label={`${t('Task Name')}`}
                    showCounter
                    maxLength={TASK_MAX_TITLE_LENGTH}
                />

                {(isPublishDateEnabled && isClone) && (
                    <Box className={classes.dateWrapper}>
                        <DateInput
                            name="publishDate"
                            label={`${t('Publish Date')}`}
                            valueFormat={TASK_DATE_FORMAT}
                            minDate={new Date().toString()}
                        />
                    </Box>
                )}

                {(isPublishDateEnabled && isClone) && (
                    <Box className={classes.recurrenceWrapper}>
                        <RecurrenceDropdown
                            name="recurrence"
                            label={`${t('Recurrence')}`}
                            refDateName="publishDate"
                        />
                    </Box>
                )}

                <Box className={classes.dateWrapper}>
                    <DateInput
                        name="dueDate"
                        label={`${t('Due Date')}`}
                        valueFormat={TASK_DATE_FORMAT}
                        minDate={isClone ? new Date().toString() : undefined}
                    />
                    {hideToggleExpiredDate
                        ? <></> :
                        <ToggleExpiredDate isClone={isClone} value={valueIsExpirable} handleChange={handleChange} />}
                </Box>
                <Box className={classes.recurrenceWrapper}>
                    {isWatchersEnabled && (
                        <WatchersDropdown
                            name="watchers"
                            label={`${t('Watchers')}`}
                            cretedBy={valueCreatedBy}
                        />

                    )}
                    {isNtfTaskDueReminderEnabled && (
                        <SwitchCustom
                            name="sendReminder"
                            label={t('Send Reminders')}
                            titleTooltip={<Trans>Sends Task Reminder Notification for Incomplete Tasks 12 & 6 Hours Before Due</Trans>}
                            bodyTooltip={<ContactSupportIcon color="inherit" fontSize="small" />}
                            showTooltip
                        />)}
                </Box>
                <MarkdownInput
                    name="description"
                    label={`${t('Description (optional)')}`}
                    classes={{ root: classes.descriptionInput }}
                />

                <AttachmentsEdit />

                {isClone ? 
                    <TaskLocationsCreate fieldName='locationIds' fieldNameFromTemplate='locationsFromTemplate' />
                    : <TaskLocationsView fieldName='locationIds' />
                }
                <TaskChecklist fieldName='checklistItems' />
            </Box>
            <FormActions 
                isInProgress={isUpdating} 
                btnAnotherText={!isTasksTemplateEnabled ? '' : saveAsTemplateBtn}
                onClickBtnAnother={handleSubmitNewTemplate}
            />
        </form>
    );
}
