import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { IBaseInputClasses } from 'components/basicInputs/model';

export interface IBaseInputProps {
    classes?: IBaseInputClasses;
    id?: string;
    label: React.ReactNode;
    children: React.ReactNode,
    separateLabel?: boolean,
}

export function BaseInput({
    id, label, children, separateLabel = true, classes: { label: labelClass = '', root: rootClass = '' } = {},
}: IBaseInputProps) {
    const classes = useInputsStyles([]);

    return (
        <Box className={clsx(classes.root, rootClass)}>
            {separateLabel && (
                <label className={clsx(classes.label, labelClass)} htmlFor={id}>
                    {label}
                </label>
            )}
            {children}
        </Box>
    );
}
