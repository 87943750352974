import React from 'react';
import { useChatContext } from 'stream-chat-react';
import { useDeleteConversation } from 'modules/connect/messenger/hooks/useDeleteConversation';
import { IconButton } from '@material-ui/core';
import { Block as BlockIcon } from '@material-ui/icons';

export function DeleteAction() {
    const { channel } = useChatContext();
    const { deleteConversation, isChannelDeleting } = useDeleteConversation(channel?.id);

    if (!deleteConversation) {
        return null;
    }

    return (
        <IconButton onClick={deleteConversation} disabled={isChannelDeleting}>
            <BlockIcon color="primary" />
        </IconButton>
    );
}
