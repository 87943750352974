import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { InputAdornment } from '@material-ui/core';
import { Close, DateRange } from '@material-ui/icons';
import { BaseInput } from 'components/basicInputs/BaseInput';
import { IInputProps } from 'components/basicInputs/model';
import { useField } from 'formik';
import { InputEndAdornment } from 'components/basicInputs/InputEndAdornment';
import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import { hasFormikError } from '../utils';
import { formatDateLocalized, shortDateFormat } from 'utils/helpers/dateHelper';
import { InputError } from '../InputError';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/en-au";
import i18n from 'i18n';

interface IDateInputProps extends IInputProps {
    disabled?: boolean;
    clearable?: boolean;
    valueFormat?: string;
    minDate?: string;
    maxDate?: string;
    updateValue?: string
    onSelectDate?: () => void
}

export function DateInput({
    name,
    label,
    id = name,
    clearable = false,
    valueFormat,
    disabled,
    minDate,
    maxDate,
    updateValue = '',
    onSelectDate = ()=>{}
}: IDateInputProps) {
    const classes = useInputsStyles([]);
    const [open, setOpen] = useState(false);

    const [field, meta] = useField(name);
    const [active, setActive] = useState(false);
    const { value, onChange } = field;
    const hasError = hasFormikError(meta);

    useEffect(()=>{
        if(updateValue){
            onChange({ target: { name, value: null } });
        }
    },[name, onChange, updateValue])

    const onOpen = useCallback(() => {
        setOpen(true);
        setActive(true);
    }, [setActive]);

    const onClose = useCallback(() => {
        setOpen(false);
        setActive(false);
    }, [setActive, setOpen]);

    const onDatePicked = useCallback((date: MaterialUiPickersDate) => {
        const formattedValue = date && date.isValid() ? date.format(valueFormat) : '';
        onChange({ target: { name, value: formattedValue } });
        onSelectDate();
    }, [name, valueFormat, onChange, onSelectDate]);

    const onReset = useCallback(() => {
        setOpen(false);
        onChange({ target: { name, value: null } });
    }, [name, onChange, setOpen]);

    const labelFn = useCallback((date) => {
        return date
            ? formatDateLocalized(date.toDate(), shortDateFormat)
            : '';
    }, []);

    const endAdornment = clearable && active && value
        ? (
            <InputAdornment position="end" onMouseDown={onReset}>
                <Close className={classes.clickableIcon} />
            </InputAdornment>
        )
        : (
            <InputEndAdornment
                value={value}
                hasError={hasError}
            />
        );

    moment.locale(i18n.language);

    return (
        <BaseInput id={id} label={label}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language} libInstance={moment} >
                <DatePicker
                    disabled={disabled}
                    variant="inline"
                    value={value === '' ? null : value}
                    inputVariant="outlined"
                    id={id}
                    open={open}
                    onChange={onDatePicked}
                    labelFunc={labelFn}
                    onOpen={onOpen}
                    onClose={onClose}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <DateRange />
                            </InputAdornment>
                        ),
                        endAdornment,
                    }}
                    autoOk
                    initialFocusedDate=""
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </MuiPickersUtilsProvider>
            {hasError && (
                <InputError error={meta.error} />
            )}
        </BaseInput>
    );
}
