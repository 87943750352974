import { ItemsById } from 'model/entity';
import { combineReducers } from 'redux';
import { TasksStatsByLocationActions, requestTasksStatsByLocation } from 'api/task/statistics/statisticsActions';
import { TasksRangeDate, TasksStatistics } from 'api/task/statistics/statisticsModel';
import {
    RESET_TASKS_RANGE_DATE,
    RESET_TASKS_STATISTICS,
    TasksStatisticsActions,
    TASKS_RANGE_DATE,
    EMPTY_TASKS_RANGE_DATE,
    TASKS_TAGS,
    RESET_TASKS_TAGS
} from './statisticsActions';
import { firstDayOfTheMonth, todayMoment } from 'utils/helpers/dateHelper';

const initialState = {
    byLocationId: {},
    loadingByLocationId: {},
    byLocationRangeDate: {
        startDate: firstDayOfTheMonth(),
        endDate: todayMoment()
    },
    byLocationTags: [],
    emptyByLocationDateRange: {
        startDate: undefined,
        endDate: undefined,
    },
};

export function byLocationId(
    state: ItemsById<TasksStatistics> = initialState.byLocationId,
    action: TasksStatsByLocationActions | TasksStatisticsActions,
): ItemsById<TasksStatistics> {
    switch (action.type) {
        case requestTasksStatsByLocation.successType:
            const { locationId, stats } = action.payload;

            return { ...state, [locationId]: stats };
        case RESET_TASKS_STATISTICS:
            return {};
        default:
            return state;
    }
}

export function byLocationRangeDate(
    state: TasksRangeDate = initialState.byLocationRangeDate,
    action: TasksStatisticsActions,
): TasksRangeDate {
    switch (action.type) {
        case TASKS_RANGE_DATE:
            return action.payload;
        case RESET_TASKS_RANGE_DATE:
            return initialState.byLocationRangeDate;
        case EMPTY_TASKS_RANGE_DATE:
            return initialState.emptyByLocationDateRange;
        default:
            return state;
    }
}

export function byLocationTags(
    state: string[] = initialState.byLocationTags,
    action: TasksStatisticsActions,
): string[] {
    switch (action.type) {
        case TASKS_TAGS:
            return action.payload;
        case RESET_TASKS_TAGS:
            return [];
        default:
            return state;
    }
}

function loadingByLocationId(
    state: ItemsById<boolean> = initialState.loadingByLocationId,
    action: TasksStatsByLocationActions | TasksStatisticsActions,
): ItemsById<boolean> {
    switch (action.type) {
        case requestTasksStatsByLocation.initType:
            return { ...state, [action.payload.locationId]: true };
        case requestTasksStatsByLocation.successType:
            return { ...state, [action.payload.locationId]: false };
        case requestTasksStatsByLocation.errorType:
            return { ...state, [action.payload.id]: false };
        case RESET_TASKS_STATISTICS:
            return {};
        default:
            return state;
    }
}

export const statisticsReducer = combineReducers({
    byLocationId,
    loadingByLocationId,
    byLocationRangeDate,
    byLocationTags
});
