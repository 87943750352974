import React, { MouseEventHandler, useCallback } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { BlueColor } from 'utils/styles/constants';
import { PinIcon, useChannelActionContext, useChatContext } from 'stream-chat-react';
import { StreamChatType } from '../../../MessageInput/hooks/types';
import { useWorkspaceController } from './WorkspaceController';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

const useStyles = makeStyles(() => ({
    actionIcon: {
        fill: BlueColor.Blue70,
    },
}));

export const PinAction: React.FC = () => {
    const isConnectPinMessageEnabled = useFeatureState(FeatureFlags.ConnectPinMessage);
    const { channel } = useChatContext();
    const { closeThread } = useChannelActionContext<StreamChatType>();
    const { togglePinnedMessageListOpen } = useWorkspaceController();
    const classes = useStyles();
    const onPinIconClick: MouseEventHandler = useCallback((event) => {
        closeThread?.(event);
        togglePinnedMessageListOpen();
    }, [closeThread, togglePinnedMessageListOpen])
    
    if (!isConnectPinMessageEnabled || !channel) {
        return null;
    }

    return (
        <IconButton onClick={onPinIconClick} className={classes.actionIcon}>
            <PinIcon />
        </IconButton>
    );
}
