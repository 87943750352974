import React, { useCallback } from 'react';
import { Box, Button, CircularProgress, Dialog, makeStyles, Theme, Typography } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageEditor } from './ImageEditor';
import { Formik } from 'formik';
import { useButtonStyles } from '../../../../utils/styles/buttons';
import clsx from 'clsx';
import { Breakpoints, UtilColors } from '../../../../utils/styles/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvatarEditorOpened, selectUsersUpdatingById } from '../../../../store/modules/user/userSelectors';
import { openAvatarEditor } from '../../../../store/modules/user/userActions';
import { requestUploadAvatar } from '../../../../api/files/filesActions';
import { PendingFile } from '../../../../store/modules/files/filesModel';
import { selectIsAvatarUploading } from '../../../../store/modules/files/filesSelectors';
import { useCurrentUser } from '../../../../utils/hooks/useCurrentUser';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '95%',
        margin: '0 auto',
        maxWidth: theme.spacing(103),
        paddingTop: theme.spacing(3),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            paddingTop: theme.spacing(1.5),
        },
    },
    circular: {
        float: 'right',
    },
    actionBarWrapper: {
        paddingBottom: theme.spacing(3),
        margin: '15px',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            paddingBottom: theme.spacing(1.5),
        },
    },
    childrenWrapper: {
        boxSizing: 'border-box',
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(4.75, 12),
        marginBottom: theme.spacing(9),
        backgroundColor: UtilColors.White,

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            padding: theme.spacing(4.75, 4),
        },

        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            padding: theme.spacing(3, 1.5),
        },
    },
    pageTitle: {
        marginBottom: theme.spacing(3),
    },
    boxInner1: {
        maxWidth: '500px',
        overflowX: 'hidden',
        minWidth: '25vw',
    },
    dialogBox: {
        overflowX: 'hidden',
    },
}));

export interface IUpdateLogoFormData {
    Avatar: string;
}

export function CropperModal() {
    const dispatch = useDispatch();
    const buttonClasses = useButtonStyles();
    const classes = useStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');
    const isOpen = useSelector(selectAvatarEditorOpened);
    const user = useCurrentUser();
    const isUploadingAvatar = useSelector(selectIsAvatarUploading);
    const isUpdatingUser = useSelector(selectUsersUpdatingById)[user?.id || ''];
    const isInProgress = isUploadingAvatar || isUpdatingUser;

    const handleCancel = useCallback(() => {
        dispatch(openAvatarEditor(false));
    }, [dispatch])

    const handleSave = useCallback(async (data: IUpdateLogoFormData) => {
        const binaryImage = await fetch(data.Avatar);
        const blob = await binaryImage.blob();
        const file = new File([blob], 'avatar.jpeg', {type: 'image/jpeg'});
        const pendingFile = {
            localFile: file,
            localKey: '',
            title: 'avatar.jpeg',
        } as PendingFile;
        dispatch(requestUploadAvatar.init(pendingFile));
    }, [dispatch]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            fullScreen={false}
        >
            <Box className={classes.dialogBox}>
                <Box margin={'15px'}>
                    <Typography
                        variant="h3"
                        className={classes.pageTitle}
                    >
                        <Trans>Replace Avatar</Trans>
                    </Typography>
                </Box>
                <Formik
                    initialValues={{Avatar: ''}}
                    onSubmit={handleSave}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Box className={classes.boxInner1}>
                                <Box>
                                    <ImageEditor
                                        name={`${t('Avatar')}`}
                                    />
                                </Box>
                                <Box display={'flex'}>
                                    <Box marginLeft={'auto'}>
                                        <Box margin={'15px'}>
                                            <Button
                                                className={buttonClasses.muiRoundedButton}
                                                onClick={handleCancel}
                                                disabled={isInProgress}
                                            ><Trans>Cancel</Trans></Button>
                                            {isInProgress && (
                                                <CircularProgress className={classes.circular} />
                                            )}
                                            {!isInProgress && (
                                                <Button
                                                    className={saveButtonClasses}
                                                    type={'submit'}
                                                    style={{ marginLeft: '15px' }}
                                                    disabled={isInProgress}
                                                ><Trans>Save</Trans></Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    );
}
