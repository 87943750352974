import { TaskListPageLayout } from "../../components/TaskListPageLayout";
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { SimpleTaskListHeader } from "../../components/SimpleTaskListHeader";
import { useFixedSizeListHeight } from "../../hooks/useFixedSizeListHeight";
import { ScheduledTasksGrid } from "./ScheduledTasksGrid";
import { useScheduledTasksGridData } from "./useScheduledTasksGridData";
import { useDispatch, useSelector } from "react-redux";
import { selectScheduledTasksFetching } from "store/modules/task/scheduledTask/scheduledTaskSelectors";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SetScheduledTaskProvider } from "./context";
import { deleteTaskTemplate } from 'api/task/scheduledTask/scheduledTaskActions';
import { FlexBox } from "modules/shared/components/FlexBox";
import { emptyTaskRangeDate } from '../../../../../store/modules/task/statistics/statisticsActions';
import { Trans } from "react-i18next";
import { ButtonSwitchType } from "../../components/ViewSwitch/TypeViewSwithMap";
import { ViewSwitch } from "../../components/ViewSwitch/ViewSwitch";

export const ScheduledTasksView = () => {
    const dispatch = useDispatch();
    const { companyLocationName, isCompanyLocationStore = true } = useCurrentUser() || {};
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();
    const data = useScheduledTasksGridData()
    const isFetching = useSelector(selectScheduledTasksFetching);
    const [taskToDelete, setTaskToDelete] = useState<string | null>(null);
    const handleDeleteTask = useCallback(() => {
        if (taskToDelete) {
            dispatch(deleteTaskTemplate.init(taskToDelete));
            setTaskToDelete(null);
        }
    }, [dispatch, taskToDelete]);
    useEffect(() => {
        dispatch(emptyTaskRangeDate());
    }, [dispatch]);

    const header = useMemo(() => {
        if (isCompanyLocationStore) { // regular user
            return (
                <FlexBox sx={{flexDirection: ['column','row']}}>
                    <SimpleTaskListHeader regionName={companyLocationName ?? ''} />
                    <ViewSwitch type={ButtonSwitchType.StoreTasks} />
                </FlexBox>
            );
        }

        // multi-site manager
        return (
            <>
                <SimpleTaskListHeader regionName={companyLocationName ?? ''} />
                <FlexBox mt={1}>
                    <ViewSwitch type={ButtonSwitchType.ParentTasks} />
                </FlexBox>
            </>
        );
    }, [isCompanyLocationStore, companyLocationName]);

    return (
        <TaskListPageLayout
            header={header}
            layoutHeaderRef={layoutHeaderRef}
        >
            <SetScheduledTaskProvider value={setTaskToDelete}>
                <ScheduledTasksGrid
                    data={data}
                    isFetching={isFetching}
                    height={gridHeight}
                />
            </SetScheduledTaskProvider>

            <ConfirmationDialog
                text={(<Trans>
                    This action will stop selected task from being scheduled and remove it from the list.
                    All already published tasks will remain intact
                </Trans>)}
                open={!!taskToDelete}
                onDiscard={() => setTaskToDelete(null)}
                onAgree={handleDeleteTask}
            />
        </TaskListPageLayout>
    );
};
