import {
    UploadsPreview,
    useChannelStateContext,
    useComponentContext,
    useMessageInputContext,
} from 'stream-chat-react';
import { MessageInputControlButton } from './MessageInputControls';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useEffect, useRef } from 'react';
import { CustomChatAutoComplete } from './CustomAutoCompleteTextarea';
import { StreamChatType } from './hooks/types';

export const CustomMessageInput = () => {
    const { TypingIndicator } = useComponentContext();
    const refSendbtn = useRef<any>(null);

    const { multipleUploads } = useChannelStateContext<StreamChatType>();
    const {
        numberOfUploads,
        uploadNewFiles,
        maxFilesLeft,
        isUploadEnabled,
        openEmojiPicker,
        closeEmojiPicker,
        emojiPickerIsOpen,
    } = useMessageInputContext();
    const containerRef = useRef<HTMLDivElement>(null);

    const {
        getRootProps,
        isDragActive,
        isDragReject,
        inputRef,
        getInputProps,
    } = useDropzone({
        disabled: !isUploadEnabled || maxFilesLeft === 0,
        multiple: multipleUploads,
        noClick: true,
        onDrop: uploadNewFiles,
    });

    const executeScroll = () => containerRef?.current?.scrollIntoView(); 

    useEffect(()=>{
        setTimeout(() => {
            if(containerRef?.current){
                executeScroll();
            }
        }, 1000);
    },[])

    return (
        <div
            {...getRootProps({
                className: clsx(`team-message-input__wrapper`),
            })}
        >
            {isDragActive && (
                <div
                    className={clsx("str-chat__dropzone-container", {
                        "str-chat__dropzone-container--not-accepted":
                            isDragReject,
                    })}
                >
                    {!isDragReject && <p>Drag your files here</p>}
                    {isDragReject && (
                        <p>Some of the files will not be accepted</p>
                    )}
                </div>
            )}
            <div className="team-message-input__input" ref={containerRef}>
                <div className="team-message-input__top">
                    {!!numberOfUploads && <UploadsPreview />}
                    <div
                        className="team-message-input__form"
                        ref={refSendbtn}
                    >
                        <CustomChatAutoComplete /> 
                    </div>
                </div>
                <div className="team-message-input__bottom">

                    <input {...getInputProps()} />
                    <MessageInputControlButton
                        type="emoji"
                        onClick={
                            emojiPickerIsOpen
                                ? closeEmojiPicker
                                : openEmojiPicker
                        }
                    />
                    <MessageInputControlButton
                        type="fileUploadIcon"
                        onClick={() => inputRef.current?.click()}
                    />
                </div>
            </div>
            {TypingIndicator && <TypingIndicator />}
        </div>
    );
};
