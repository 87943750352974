import React, { useCallback, useMemo } from "react";
import CustomGetStreamAutocomplete from "./CustomGetStreamAutocomplete";
import { ChatAutoCompleteProps, LoadingIndicator, useChannelStateContext, useChatContext, useComponentContext, useMessageInputContext, useTranslationContext } from "stream-chat-react";
import { CustomTrigger, DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { StreamChatType } from "./hooks/types";

const UnMemoizedChatAutoComplete = <
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
    V extends CustomTrigger = CustomTrigger
>(
    props: ChatAutoCompleteProps,
) => {
    const {
        AutocompleteSuggestionItem: SuggestionItem,
        AutocompleteSuggestionList: SuggestionList,
    } = useComponentContext<StreamChatGenerics, V>('ChatAutoComplete');
    const { t } = useTranslationContext('ChatAutoComplete');

    const { client } = useChatContext<StreamChatType>();
    const { channel } = useChannelStateContext<StreamChatType>();
    const messageInput = useMessageInputContext<StreamChatGenerics, V>('ChatAutoComplete');
    const { cooldownRemaining, disabled, emojiIndex, textareaRef: innerRef, message } = messageInput;

    const placeholder = useMemo(() => {
        let dynamicPart = "the group";

        if (channel.type === "team") {
            dynamicPart = `#${
                channel?.data?.name || channel?.data?.id || "random"
            }`;
        }

        const members = Object.values(channel.state.members).filter(
            ({ user }) => user?.id !== client.userID
        );
        if (!members.length || members.length === 1) {
            dynamicPart =
                members[0]?.user?.name ||
                members[0]?.user?.id ||
                "Johnny Blaze";
        }

        return `Message ${dynamicPart}`;
    }, [
        channel.type,
        channel.state.members,
        channel?.data?.id,
        channel?.data?.name,
        client.userID,
    ]);

    const emojiReplace = props.wordReplace
        ? (word: string) => props.wordReplace?.(word, emojiIndex)
        : (word: string) => {
            const found = emojiIndex?.search(word) || [];
            const emoji = found
            .filter(Boolean)
            .slice(0, 10)
            .find(({ emoticons }: any) => !!emoticons?.includes(word));
            if (!emoji || !('native' in emoji)) return null;
            return emoji.native;
        };

    const updateInnerRef = useCallback(
        (ref: HTMLTextAreaElement | null) => {
        if (innerRef) {
            innerRef.current = ref;
        }
        },
        [innerRef],
    );

    return (
        <CustomGetStreamAutocomplete
            additionalTextareaProps={messageInput.additionalTextareaProps}
            aria-label={cooldownRemaining ? t('Slow Mode ON') : placeholder}
            className='str-chat__textarea__textarea str-chat__message-textarea'
            closeCommandsList={messageInput.closeCommandsList}
            closeMentionsList={messageInput.closeMentionsList}
            containerClassName='str-chat__textarea str-chat__message-textarea-react-host'
            disabled={disabled || !!cooldownRemaining}
            disableMentions={messageInput.disableMentions}
            dropdownClassName='str-chat__emojisearch'
            grow={messageInput.grow}
            handleSubmit={props.handleSubmit || messageInput.handleSubmit}
            innerRef={updateInnerRef}
            itemClassName='str-chat__emojisearch__item'
            listClassName='str-chat__emojisearch__list'
            loadingComponent={LoadingIndicator}
            minChar={0}
            onBlur={props.onBlur}
            onChange={props.onChange || messageInput.handleChange}
            onFocus={props.onFocus}
            onPaste={props.onPaste || messageInput.onPaste}
            replaceWord={emojiReplace}
            rows={props.rows || 1}
            shouldSubmit={messageInput.shouldSubmit}
            showCommandsList={messageInput.showCommandsList}
            showMentionsList={messageInput.showMentionsList}
            SuggestionItem={SuggestionItem}
            SuggestionList={SuggestionList}
            trigger={messageInput.autocompleteTriggers || {}}
            value={message?.text || props.value || messageInput.text }
            setText={messageInput.setText}
            numberofuploads={messageInput.numberOfUploads}
            isEdition={!!message?.text}
            mentionQueryParams={{filters: {deactivated_at:{ $exists: false}} }}
            mentionedUsers={messageInput.mentioned_users}
            clearEditingState={messageInput.clearEditingState}
            />
        );
};

export const CustomChatAutoComplete = React.memo(
    UnMemoizedChatAutoComplete,
) as typeof UnMemoizedChatAutoComplete;
