import 'stream-chat-react/dist/css/index.css';
import Box from '@material-ui/core/Box';
import styled from '@material-ui/core/styles/styled';
import { Breakpoints, UtilColors, ZIndex } from 'utils/styles/constants';

const base = '& .str-chat.connect';
const chatSidebar = `${base}.str-chat-channel-list`;
const chatSidebarWrapper = `${chatSidebar} .chat-sidebar-wrapper`;
const chatMainContentWrapper = `${base}.str-chat-channel`;
const chatContainer = `${chatMainContentWrapper} .str-chat__container`;
const chatMainPanel = `${chatContainer} .str-chat__main-panel`;
const chatThread = `${chatContainer} .str-chat__thread`;
const chatHeader = `${chatMainPanel} .str-chat__header-livestream`;
const chatMessageList = `${chatMainPanel} .str-chat__list`;
const messageContainer = `${base} .str-chat__message-input`;
const messageReceipts = `${chatMessageList} .str-chat__message-team-status`;
const messageAttachment = `${chatMessageList} .str-chat__message-attachment`;
const messageSendButton = `${base} .str-chat__send-button`;
const emojiContainer = `${base} .str-chat__emoji-picker-container`;
const emojiSection = `${emojiContainer} .emoji-mart`;
const emojiselectIcon = `${base} .str-chat__edit-message-form .str-chat__input-emojiselect`;
const editMessageThread = `${base} .str-chat__message-team-actions .str-chat__message-actions-box--open`;
const threadHeader = `${base} .str-chat__thread-header`;
const threadHeaderButton = `${threadHeader} .str-chat__square-button`;
const teamMessage = `${base} .team-message-input__wrapper`;
const messageCode = `${base} .str-chat__message-team-content p code`;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MessengerStyles = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    backgroundColor: UtilColors.Background,
    boxSizing: 'border-box',

    [base]: {
        height: '100%',
    },
    [chatSidebar]: {
        boxSizing: 'border-box',
        height: '100%',
        width: '322px',
        padding: '20px',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            position: 'absolute',
            width: '285px',
            top: 0,
            left: '-300px',
            bottom: 0,
            zIndex: ZIndex.Sidebar,
            padding: 0,
            transition: 'left 0.1s ease-in-out',

            '&.opened': {
                left: 0,
                zIndex: ZIndex.Sidebar,
            },
        },
    },
    [chatSidebarWrapper]: {
        backgroundColor: UtilColors.White,
        borderRadius: '20px',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: '20px 0',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            borderRadius: '0 20px 0 0',
        },
    },
    [chatContainer]: {
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            padding: '20px',
        },
    },
    [chatMainPanel]: {
        background: UtilColors.White,

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            borderRadius: '20px',
            overflow: 'hidden',
        },
    },
    [chatThread]: {
        background: UtilColors.White,

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            borderRadius: '20px',
            width: '100%',
            overflow: 'hidden',
            minWidth: '200px',
            flexBasis: 'auto',
        },

        [theme.breakpoints.down(Breakpoints.DesktopSmall)]: {
            maxWidth: 'none',
        },
    },
    [chatHeader]: {
        padding: theme.spacing(1.25, 1.25, 1.25, 2.5),

        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
            padding: theme.spacing(0.75, 0, 0.75, 1),
        },
    },
    [chatMessageList]:{
        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
            '-webkit-overflow-scrolling': 'auto'
        }, 
    },
    [messageReceipts]: {
        display: 'none',
    },
    [messageAttachment]:{
        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
            width: '240px',
            '--attachment-max-width': '240px',
            overflow: 'auto'
        }, 
    },
    [emojiSection]:{
        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
            width: 'min-content!important',
        }, 
    },
    [messageContainer]:{
        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
            padding: theme.spacing(1),
        },
    },
    [messageSendButton]:{
        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(3)
        }, 
    },
    [emojiselectIcon]:{
        width: '40px'
    },
    [editMessageThread]:{
        left: 'auto',
        top: 'auto'
    },
    [threadHeaderButton]:{
        padding: '2px',
        cursor: 'pointer',
        '& svg': {
            width: '18px'
        }
    },
    [messageCode]:{
        whiteSpace: 'pre-line',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        '-ms-word-break': 'break-all',
        wordBreak: 'break-word',
        '-webkit-hyphens': 'auto',
        hyphens: 'auto',
    },
    [teamMessage]: {
        display: 'flex',
        alignItems: 'center',
        padding: '15px 20px 28px',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',

        '& .team-message-input__input': {
            border: '1px solid rgba(0,0,0,.2)',
            borderRadius: '8px',
            overflow: 'hidden',
            width: '100%',
            '& .team-message-input__top': {
                minHeight: '43px',
                width: '100%',
                background: 'white',

                '& .team-message-input__form': {
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: 10,
                    [theme.breakpoints.down(Breakpoints.MobileAdvanced)]: {
                        paddingRight: 0
                    },

                    '& .str-chat__send-button': {
                        display: 'flex',
                        alignItems: 'center',
                        margin: 'auto',
                        cursor: 'pointer',
                        '& svg' : {
                            width: 30,
                        }
                    },

                    '& .str-chat__suggestion-list-container': {
                        width: 'unset',
                        bottom: '85%',
                        right: '20px',
                        left: '20px',
                        '& .str-chat__suggestion-list li:hover': {
                            backgroundColor: theme.palette.primary.main,
                            width: '100%',
                            '& span': {
                                color: '#FFFFFF',
                            }
                        },
                    }
                }
            },

            '& .team-message-input__bottom': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                height: '40px',
                background: 'rgba(0, 0, 0, 0.05)'
            }
        },

        '& .str-chat__input--emojipicker': {
            zIndex: 3,
            position: 'absolute',
            bottom: '112px',
            left: '20px',
            width: '338px',
        },
        '& textarea': {
            width: '100%',
            resize: 'none',
            padding: 0,
            border: 'none',
            outline: 'none',
            boxShadow: 'none'
        },
        
        '& .str-chat__textarea': {
            height: '0!important',
            flex: '1 1',
        },
        
        
        '& .message-input__controls-button': {
            height: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '5px',
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
                padding: theme.spacing(0, .75),
            },
            
            '& svg': {
                width: theme.spacing(3.25),
                [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
                    width: theme.spacing(2)
                }
            },
            '& path': {
                fillOpacity: 0.2,
                fill: '#000',
            },
            '&.active path': {
                fillOpacity: 1,
                fill: theme.palette.primary.main,
            },
            '&:hover path': {
                fill: theme.palette.primary.main,
                fillOpacity: 1,
            },
        },
        
        '& .str-chat__dropzone-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            zIndex: 5,
            backgroundColor: 'rgba(0,95,255,0.6)',
            color: 'white',
            backdropFilter: 'blur(3px)',
        },
        
        '& .giphy-icon__wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '24px',
            width: '63px',
            marginLeft: '8px',
        },
        
        '& .rfu-image-previewer': {
            flex: 'none',
            marginLeft: '12px',
        },
        
        '& .rfu-image-previewer__image': {
            marginBottom: 0,
        },
        '& .str-chat__attachment-preview-list': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.375rem',
            width: '100%',
            '& .str-chat__attachment-list-scroll-container': {
                overflowY: 'hidden',
                overflowX: 'auto',
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gridColumnGap: '0.5rem',
                '-webkit-column-gap': '0.5rem',
                columnGap: '0.5rem',
                flexWrap: 'wrap'
            },
            '& .str-chat__attachment-preview-image': {
                position: 'relative',
                height: 60,
                width: 60,

                '& .str-chat__attachment-preview-thumbnail': {
                    objectFfit: 'cover',
                    height: 60,
                    width: 60,
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                    borderRadius: 14,
                    backgroundColor: 'hsla(0,0%,98.8%,0.9)',
                }
            },
            '& .str-chat__attachment-preview-delete': {
                position: 'absolute',
                top: 2,
                insetInlineEnd: 2,
                cursor: 'pointer',
                zIndex: 1,
                margin: 0,
                padding: 0,
                border: 'none',
                display: 'flex',
                borderRadius: '50%',
                '& svg': {
                    width: 24,
                    height: 24,
                    fill: '#a0b2b8'
                }
            }
        },
    }
}));
