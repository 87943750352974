import React, { useEffect, useMemo, useState, useContext } from 'react';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { useDispatch, useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { selectStoreTasksFetching } from 'store/modules/task/storeTask/storeTaskSelectors';
import { useActions } from "utils/store/useActions";
import { SimpleTaskListHeader } from '../../components/SimpleTaskListHeader';
import { TaskListPageLayout } from '../../components/TaskListPageLayout';
import { useFixedSizeListHeight } from '../../hooks/useFixedSizeListHeight';
import { StoreTasksGrid } from './grid/StoreTasksGrid';
import { useStoreTasksGridData } from './grid/useStoreTasksGridData';
import { useQueryParams } from 'utils/hooks';
import { TaskQueryParams } from 'modules/task/common/model';
import { validate } from 'uuid';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { StoreTaskStatusFilter } from 'api/task/storeTask/storeTaskModel';
import { makeStyles, Theme } from '@material-ui/core';
import { allTasksSyntheticValue, getAutomationTaskFilterOptions, getStoreTaskFilterOptions } from '../../components/model';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { FlexBox } from 'modules/shared/components/FlexBox';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { emptyTaskRangeDate } from '../../../../../store/modules/task/statistics/statisticsActions';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { TaskTaglistMultiselect } from 'modules/shared/components/TaskTaglistMultiselect';
import { RemoveParentTaskConfimation } from '../ParentTasksView/RemoveParentTaskConfirmation';
import { SearchTasks } from '../../components/SearchTasks';
import { TasksFilterContext } from '../../context/taskFilterContext';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { AutomationTasksGrid } from '../../components/AutomationTaskGrid';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { ViewSwitch } from '../../components/ViewSwitch/ViewSwitch';
import { ButtonSwitchType } from '../../components/ViewSwitch/TypeViewSwithMap';

export type StoreTasksViewParams = {
    locationId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    statusFilter: {
        width: '100%',
        maxWidth: '205px',
        display: 'flex',
        justifyContent: 'center'
    },
    select: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        lineHeight: '24px',
    },
}));

export const StoreTasksView = ({ locationId }: StoreTasksViewParams) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isExpirableColumnsFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.TaksExpirable];
    const data = useStoreTasksGridData();
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const location = useSelector(selectLocationsByIds)[locationId];
    const areStoreTasksFetching = useSelector(selectStoreTasksFetching);
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();
    const { openTask: openTaskId } = useQueryParams<TaskQueryParams>();
    const [searchTasks, setSearchTasks] = useState<string>('');
    const isTasksSearchEnabled = useFeatureState(FeatureFlags.TasksSearch);
    const typeTasksAppAccess = useSelector(selectHasAppAccess(AppName.Tasks)) ? ButtonSwitchType.StoreTasks : ButtonSwitchType.NoAccessTaskPage;

    const { 
        valuesFiltersTasks,
        handleFilterTag,
        handleSetFilterStatus,
        showFilterTag,
        showTableTask,
    } = useContext(TasksFilterContext);

    const options = useMemo(() => {
        return valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ? getStoreTaskFilterOptions(isOverdueColumnsFeature?.enabled ?? false, isExpirableColumnsFeatureEnabled?.enabled ?? false)
        : getAutomationTaskFilterOptions()
    }, [isOverdueColumnsFeature, isExpirableColumnsFeatureEnabled, valuesFiltersTasks.tasksViewMode]);

    useEffect(() => {
        dispatch(emptyTaskRangeDate());
    }, [dispatch]);

    const simpleTaksListHeaderAutomation = useMemo(()=>(
        <SimpleTaskListHeader regionName={location?.name} />
    ),[location ]);

    const header = useMemo(() => {
        return (
            <FlexBox flexWrap="wrap" width="100%" justifyContent="space-between">
                <FlexBox flexWrap="wrap" width={{xs: '100%', sm: '100%', md:'100%',lg:'calc(100% - 243px)'}}>
                {simpleTaksListHeaderAutomation}
                <ViewSwitch type={typeTasksAppAccess} />
                <OptionsDropdown
                    className={classes.statusFilter}
                    selectClasses={{ select: classes.select }}
                    unsetValue={allTasksSyntheticValue}
                    options={options}
                    value={valuesFiltersTasks.filter}
                    onValueChange={handleSetFilterStatus}
                />
                {showFilterTag && <TaskTaglistMultiselect 
                    onTagsValuesChange={handleFilterTag}
                    initialTagSelected={valuesFiltersTasks.tags}
                />}
                </FlexBox>
                {isTasksSearchEnabled  && <SearchTasks 
                    onSearchTaskValuesChange={setSearchTasks}
                />}
            </FlexBox>
        );
    }, [classes, handleSetFilterStatus, options, isTasksSearchEnabled, handleFilterTag, showFilterTag, valuesFiltersTasks, simpleTaksListHeaderAutomation, typeTasksAppAccess]);

    const actions = useActions({
        setModalTaskId: storeTaskSetActiveId,
    });

    useEffect(() => {
        if (openTaskId && validate(openTaskId)) {
            actions.current.setModalTaskId({taskId: openTaskId});
        }
    }, [actions, openTaskId]);

    return (
        <>
            <TaskListPageLayout
                header={header}
                layoutHeaderRef={layoutHeaderRef}
                showCreateButton={canCreateTask}
            >
                { showTableTask &&
                    <>
                        { 
                            valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ?
                                <StoreTasksGrid
                                    locationId={locationId}
                                    filter={ valuesFiltersTasks.filter as StoreTaskStatusFilter | undefined}
                                    filterTag={valuesFiltersTasks.tags}
                                    data={data}
                                    isFetching={areStoreTasksFetching}
                                    height={gridHeight}
                                    searchTerm={searchTasks}
                                    tasksViewMode={valuesFiltersTasks.tasksViewMode}
                                />
                                :
                                <AutomationTasksGrid
                                    filter={valuesFiltersTasks.filter as AutomationTaskStatusFilter | undefined}
                                    filterTag={valuesFiltersTasks.tags}
                                    height={gridHeight}
                                    searchTerm={searchTasks}
                                />
                        }

                        <StoreTaskView />
                    </>
                }

            </TaskListPageLayout>
            <RemoveParentTaskConfimation isFromStoreTask/>
        </>
    );
};
