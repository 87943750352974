import React, { useCallback } from 'react';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useActions } from 'utils/store/useActions';
import { setTaskBulkToRemove } from 'store/modules/task/TasksBulk/tasksBulkActions';
import { requestDownloadTasksBulk } from 'api/files/filesActions';
import { useSelector } from 'react-redux';
import { selectIsBulkTasksFileDownloading } from 'store/modules/files/filesSelectors';

export const TasksBulkHistoryActionCell: React.FC<{id: string, fileName: string}> = ({ id, fileName }) => {
  const isBulkTasksFileDownloading = useSelector(selectIsBulkTasksFileDownloading);
  const actions = useActions({ setTaskBulkToRemove, requestFileDonwolad: requestDownloadTasksBulk.init });

  const handleDeleteTasksBulk = useCallback(()=> actions.current.setTaskBulkToRemove(id), [id, actions]);
  const handleDownloadTasksBulk = useCallback(()=> actions.current.requestFileDonwolad({fileId: id, fileName}), [id, actions, fileName]);

  return (
    <Box display="flex" style={{ gap: 4}} justifyContent="flex-end">
      <IconButton onClick={handleDownloadTasksBulk} size='small' disabled={isBulkTasksFileDownloading} >
          <GetAppIcon/>
      </IconButton>
      <IconButton onClick={handleDeleteTasksBulk} size='small'>
          <DeleteIcon/>
      </IconButton>
    </Box>
  );
};
