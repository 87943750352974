import React, { useCallback } from 'react';
import trim from 'lodash/trim';
import { Formik, FormikProps } from 'formik';
import { Box } from '@material-ui/core';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { getRawChannelName, getRawDescription, getRawFrozen } from '../../helpers';
import { EditButton } from './EditButton';
import { EditChannelDialog } from './EditChannelDialog';
import { EditChannelValidationSchema, editChannelValidationSchema } from './model';
import { Channel } from 'stream-chat';
import { useActions } from "utils/store/useActions";
import { requestEditChannel } from 'api/getStream/getStreamActions';
import { useSelector } from 'react-redux';
import { selectIsChannelUpdating } from 'store/modules/getStream/getStreamSelectors';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { ThemeProviderCustom } from 'modules/root/ThemeProviderCustom';

function getInitialValues(channel: Channel): EditChannelValidationSchema {
    return {
        name: getRawChannelName(channel) || '',
        description: getRawDescription(channel) || '',
        frozen: getRawFrozen(channel),
    };
}

export interface IEditChannelProps {
    channel: Channel;
}

export function EditChannel({ channel }: IEditChannelProps) {
    const userCanEdit = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));
    const isChannelUpdating = useSelector(selectIsChannelUpdating);
    const { isOpened, onOpen, onClose } = useOpenedState();
    const actions = useActions({ update: requestEditChannel.init });
    const initialValues = getInitialValues(channel);

    const handelSubmit = useCallback(({ name, description, frozen }, { resetForm }) => {
        actions.current.update({
            channelId: channel.id || '',
            name: trim(name),
            description: trim(description),
            frozen,
        });
        resetForm();
        onClose();
    }, [actions, channel.id, onClose]);

    if (!userCanEdit || !channel) {
        return null;
    }

    return (
        <ThemeProviderCustom>
            <Box mb={1.5}>
                <EditButton onClick={onOpen} disabled={isChannelUpdating} />
            </Box>

            <Formik
                onSubmit={handelSubmit}
                initialValues={initialValues}
                validationSchema={editChannelValidationSchema}
                enableReinitialize={true}
            >
                {(formik: FormikProps<EditChannelValidationSchema>) => (
                    <EditChannelDialog
                        isOpened={isOpened}
                        onClose={onClose}
                        formik={formik}
                        initialValues={initialValues}
                    />
                )}
            </Formik>
        </ThemeProviderCustom>
    );
}
