import { useMemo } from "react";
import { formatDateLocalized, shortDateFormat } from "utils/helpers/dateHelper";
import { EventDescription } from "./EventDescription";
import { checkTaskEvents, FieldChange, TASK_FIELDS } from "./model";
import difference from 'lodash/difference';
import { t } from "i18next";

export type TaskEditedEventBodyProps = {
    changes: FieldChange[];
}

export const TaskEditedEventBody = ({ changes }: TaskEditedEventBodyProps) => {
    const knownChanges = useMemo(() => {
        return changes
            .filter(checkTaskEvents)
            .map(({ fieldName, oldValue, newValue }) => {
                const fromValue = (oldValue as string)?.length ? `from ${oldValue}` : '';
                switch (fieldName) {
                    case TASK_FIELDS.ADD_WATCHER:
                        return `${t('Add watcher')} ${newValue}`;
                    case TASK_FIELDS.REMOVE_WATCHER:
                        return `${t('Remove watcher')} ${newValue}`;
                    case TASK_FIELDS.DUE_DATE:
                    case TASK_FIELDS.SCHEDULED_DUE_DATE: {
                        const fromDate = formatDateLocalized(oldValue as string, shortDateFormat);
                        const toDate = formatDateLocalized(newValue as string, shortDateFormat);
                        return `${t('Due Date changed from')} ${fromDate} ${t('to')} ${toDate}`;
                    }
                    case TASK_FIELDS.SCHEDULED_TITLE:
                    case TASK_FIELDS.EVENT_EVENT_TITLE:
                        return `* ${t('The title has been updated')} ${fromValue} ${t('to')} ${newValue}`;
                    case TASK_FIELDS.SCHEDULED_DESCRIPTION:
                    case TASK_FIELDS.EVENT_EVENT_DESCRIPTION:
                        return `* ${t('The description has been updated')} ${fromValue} ${t('to')} ${newValue}`;
                    case TASK_FIELDS.SCHEDULED_CHECKLIST:
                    case TASK_FIELDS.SCHEDULED_ATTACHMENTS:
                    case TASK_FIELDS.SCHEDULED_TAG:
                    case TASK_FIELDS.SCHEDULED_RECURRENCE:
                    case TASK_FIELDS.SCHEDULED_WATCHERS:
                        return `* ${fieldName.replace('schedule ', '')} ${t('was edited')}`;
                    case TASK_FIELDS.SCHEDULED_SELECTED_STORES: {
                        const oldStoresValue = (oldValue as string).split(',').map(item => item.trim());
                        const newStoresValue = (newValue as string).split(',').map(item => item.trim());
                        const storeRemoved = difference(oldStoresValue, newStoresValue);
                        return `* ${t('storeRemovedMessage', { count: storeRemoved.length, stores: storeRemoved.join(', ') })}`;
                    }
                    case TASK_FIELDS.EVENT_EVENT_END: {
                        const fromDate = formatDateLocalized(oldValue as string, shortDateFormat);
                        const toDate = formatDateLocalized(newValue as string, shortDateFormat);
                        return `* ${t('Event end date changed from')} ${fromDate} ${t('to')} ${toDate}`;
                    }
                    case TASK_FIELDS.EVENT_EVENT_START: {
                        const fromDate = formatDateLocalized(oldValue as string, shortDateFormat);
                        const toDate = formatDateLocalized(newValue as string, shortDateFormat);
                        return `* ${t('Event start date changed from')} ${fromDate} ${t('to')} ${toDate}`;
                    }
                    default:
                        throw new Error(`${t('Unexpected field name')}: ${fieldName}`);
                }
            })
    }, [changes]);

    return (
        <>
            {knownChanges.map((change, i) => (
                <EventDescription key={i}>{change}</EventDescription>
            ))}
        </>
    )
};
