import React, { useCallback, useEffect } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import {
    Channel as ConversationComponent,
    MessageList,
    Thread,
    useChatContext,
    Window,
} from 'stream-chat-react';
import { Breakpoints } from 'utils/styles/constants';
import { ChatHeader } from './ChatHeader';
import { ChannelPlaceholder } from './placeholders/ChannelPlaceholder';
import { MessageTeam } from './Message/MessageTeam';
import { StreamChatChannelType } from '../model';
import { TeamMessageInput } from './MessageInput/TeamMessageInput';
import { DirectMessageInput } from './MessageInput/DirectMessageInput';
import { getDirectMessageUserId } from '../hooks/useDirectMessageUser';
import { useDispatch } from 'react-redux';
import { requestUserInfos } from 'store/modules/user/userActions';
import { CustomMessageInput } from './MessageInput/CustomMessageInput';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { PinnedMessageList } from './ChatHeader/chatActions/PinAction/PinnedMessageList/PinnedMessageList';

export function ChatContent() {
    const dispatch = useDispatch();
    const hideOnThread = useMediaQuery((theme: Theme) => theme.breakpoints.down(Breakpoints.DesktopSmall));
    const { channel, client } = useChatContext();
    const isTeamChannel = channel?.data?.type === StreamChatChannelType.Team;
    const isCustomInputConectEnabled = useFeatureState(FeatureFlags.CustomInputConnect);

    useEffect(() => {
        if (channel?.data?.type === StreamChatChannelType.Messaging) {
            const userId = getDirectMessageUserId(client, channel);

            if (userId) {
                dispatch(requestUserInfos([userId]));
            }
        }
    }, [channel, client, dispatch])

    const propsCustomInput = useCallback(() => (
        isCustomInputConectEnabled ? {
            Input:CustomMessageInput,
            EditMessageInput:CustomMessageInput
        } : {}
    ), [isCustomInputConectEnabled]);

    return (
        <React.Fragment>
            <ConversationComponent
                EmptyPlaceholder={<ChannelPlaceholder />}
                {...propsCustomInput()}
            >
                <Window hideOnThread={hideOnThread}>
                    <ChatHeader />
                    <MessageList Message={MessageTeam} />
                    {isTeamChannel
                        ? <TeamMessageInput />
                        : <DirectMessageInput />}
                </Window>
                <Thread Message={MessageTeam} />
                <PinnedMessageList />
            </ConversationComponent>
        </React.Fragment>
    );
}
