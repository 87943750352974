import React, { useCallback } from 'react';
import { Box, CircularProgress, Typography, styled } from '@material-ui/core';
import { Sidebar } from 'components/sidebar/Sidebar';
import { Trans } from 'react-i18next';
import { UserBulkImportStep1 } from 'modules/users/user-import/UserBulkImportStep1';
import { PendingFile } from 'store/modules/files/filesModel';
import { IUserBulkAction } from 'api/files/filesModel';
import { bulkTasksTemplateUrl } from 'utils/globals';
import { useBulkTasksStyles } from './useBulkTasksStyles';
import { useSelector } from 'react-redux';
import { selectIsBulkTasksFileUploading } from 'store/modules/files/filesSelectors';
import { useActions } from 'utils/store/useActions';
import { requestTasksBulkUpload } from 'api/files/filesActions';
import { TasksBulkHistory } from './TasksBulkHistory';
import { selectResetTaskBulkFile } from 'store/modules/task/TasksBulk/tasksBulkSelectors';

export const StyledBulkTasksPanelTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    fontWeight: 600
}));

export interface BukTasksPanelProps {
    isOpened: boolean;
    onClose: () => void;
}

export const BukTasksPanel: React.FC<BukTasksPanelProps> = ({
    isOpened,
    onClose,
}) => {
    return (
        <Sidebar open={isOpened} onClose={onClose}>
            <Box
                height="100%" display="flex"
                flexDirection="column"
                mt="10px"
            >
                <TasksBulkImport onClose={onClose}/>
                <TasksBulkHistory/>
            </Box>
        </Sidebar>
    );
}

const TasksBulkImportAction: IUserBulkAction = { type: 'Import', templateUrl: bulkTasksTemplateUrl, label: <Trans>Import</Trans> };

const TasksBulkImport: React.FC<{ onClose: () => void }> = ({onClose}) => {
    const classes = useBulkTasksStyles();
    const isUploadingBulk = useSelector(selectIsBulkTasksFileUploading);
    const resetFile = useSelector(selectResetTaskBulkFile);
    const actions = useActions({
        tasksBulkUpload: requestTasksBulkUpload.init,
    });

    const handleSubmit = useCallback((files: PendingFile[]) => {
        actions.current.tasksBulkUpload(files[0])
    }, [actions]);
    
    return (
        <Box>
            <StyledBulkTasksPanelTitle variant="h5"><Box><Trans>Bulk Task Import</Trans></Box></StyledBulkTasksPanelTitle>
            {!resetFile ?
                <UserBulkImportStep1
                    isLoading={isUploadingBulk}
                    onSubmit={handleSubmit}
                    onCancel={onClose}
                    onChangeBulkAction={()=> {}}
                    bulkTypeAction={TasksBulkImportAction}
                    hideSwitch
                    customClassesHeader={classes.header}
                    hideMailConfirmation
                    customClassesContainerFile={classes.containerFile}
                    customClassesContainer={classes.container}
                    cancelClasses="dark"
                />
                : <Box height={210} display="flex" justifyContent="center" alignItems="center"><CircularProgress className={classes.resetProgress}/></Box>
            }
        </Box>
    )
}
