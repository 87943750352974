import { makeStyles } from "@material-ui/core";
import { TaskRecurrence } from "api/task/taskModel";
import { BaseInput } from "components/basicInputs/BaseInput";
import { useField } from "formik";
import { t } from "i18next";
import { OptionsDropdown } from "modules/shared/components/OptionsDropdown";
import { useCallback, useMemo } from "react";
import { FeatureFlags } from "utils/featureFlags";
import { nonEmpty } from "utils/helpers/collections";
import { formatDateCustom, getNumberOfTheDayFormatted } from "utils/helpers/dateHelper";
import { useFeatureState } from "utils/hooks/useFeatureState";

const useStyles = makeStyles({
    select: {
        lineHeight: '24px',
    },
});

const UNSET_VALUE = 'not-set';

const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export type RecurrenceDropdownProps = {
    label: string;
    name: string;
    refDateName: string;
    disabled?: boolean;
}

export const RecurrenceDropdown = ({ label, name, refDateName, disabled }: RecurrenceDropdownProps) => {
    const classes = useStyles();
    const everyWeekdayEnabled = useFeatureState(FeatureFlags.TaskRecurrenceEveryWeekday);
    const monthlyByDayEnabled = useFeatureState(FeatureFlags.TaskRecurrenceMonthlyByDay);
    const annuallyEnabled = useFeatureState(FeatureFlags.TaskRecurrenceAnnually);
    const biWeeklyEnabled = useFeatureState(FeatureFlags.TaskRecurrenceBiWeekly);
    const [field, , helpers] = useField<TaskRecurrence | undefined>(name);
    const [dateField] = useField<string>(refDateName);
    const { value } = field;
    const { value: dateValue } = dateField;

    const options = useMemo(() => {
        const weekday = formatDateCustom(dateValue, "dddd");
        const day = formatDateCustom(dateValue, "D");
        const nthDay = formatDateCustom(dateValue, "Do");
        const month = formatDateCustom(dateValue, "MMM");
        const nOfTheDay = capitalize(getNumberOfTheDayFormatted(dateValue));
        const everyWeekdayOption = {
            value: TaskRecurrence.EveryWeekday,
            title: `${t('Every Weekday (M-F)')}`,
        };
        const biWeeklyOption = {
            value: TaskRecurrence.BiWeekly,
            title: `${t('Bi-weekly')}`,
        };
        const annuallyOption = {
            value: TaskRecurrence.Annually,
            title: `${t('Annually on')} ${month} ${day}`,
        };
        const monthlyByDayOption = {
            value: TaskRecurrence.MonthlyByDay,
            title: `${t('Monthly on')} ${nOfTheDay} ${weekday}`,
        };

        return [
            { value: UNSET_VALUE, title: `${t('Does not repeat')}` },
            { value: TaskRecurrence.Daily, title: `${t('Daily')}` },
            (everyWeekdayEnabled ? everyWeekdayOption : undefined),
            { value: TaskRecurrence.Weekly, title: `${t('Weekly on')} ${weekday}` },
            (biWeeklyEnabled ? biWeeklyOption : undefined),
            { value: TaskRecurrence.Monthly, title: `${t('Monthly on')} ${nthDay}` },
            (monthlyByDayEnabled ? monthlyByDayOption : undefined),
            (annuallyEnabled ? annuallyOption : undefined),
        ].filter(nonEmpty);
    }, [dateValue, everyWeekdayEnabled, monthlyByDayEnabled, annuallyEnabled, biWeeklyEnabled])

    const onChange = useCallback((recurrence) => {
        helpers.setValue(recurrence);
    }, [helpers]);

    return (
        <BaseInput label={label}>
            <OptionsDropdown
                disabled={disabled}
                selectClasses={{ select: classes.select }}
                unsetValue={UNSET_VALUE}
                options={options}
                value={value}
                onValueChange={onChange}
            />
        </BaseInput>
    )
};
