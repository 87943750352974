export enum AppName {
    Library = 'Library',

    //#region Learning
    LearningContentLibrary = 'Learn Program Builder Program Content Library',
    LearningCourseDesigner = 'Learn Program Builder Course Designer',
    //#endregion

    //#region Admin
    Admin = 'Admin',
    AdminUsers = 'Admin Users',
    AdminRoles = 'Admin Roles',
    AdminLocations = 'Admin Locations',
    AdminProgram = 'Admin Program',
    AdminCompanyProfile = 'Admin Company Profile',
    AdminAPIClient = 'Admin API Clients',
    //#endregion

    //#region Analytics
    Analytics = 'Analytics',
    AnalyticsOverview = 'Analytics Overview',
    AnalyticsCoursesOverview = 'Analytics Courses Overview',
    AnalyticsUsersOverview = 'Analytics Users Overview',
    //#endregion

    //#region Connect
    Connect = 'Connect',
    ConnectCreateDelete = 'Connect Create/Delete',
    //#endregion

    //#region Super Admin
    SuperAdmin = 'Super Admin',
    SuperAdminUsers = 'Super Admin Users',
    SuperAdminRoles = 'Super Admin Roles',
    SuperAdminLocations = 'Super Admin Locations',
    SuperAdminProgram = 'Super Admin Program',
    SuperAdminApps = 'Platform Apps',
    SuperAdminClients = 'Super Admin Clients',
    SuperAdminData = 'Super Admin Data clean up aid',
    //#endregion

    //#region Tasks
    Tasks = 'Task Management',
    TasksCreateDelete = 'Task Management Create/Delete',
    //#endregion
}
