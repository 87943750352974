import React from 'react';

import {
    Avatar as DefaultAvatar,
    useComponentContext,
    useMessageContext,
    useTranslationContext,
} from 'stream-chat-react';

import type { TranslationLanguages } from 'stream-chat';
import { BlueColor } from '../../../../../utils/styles/constants';

export const QuotedMessage = () => {
    const { Attachment, Avatar: ContextAvatar } = useComponentContext(
        'QuotedMessage',
    );
    const { isMyMessage, message } = useMessageContext('QuotedMessage');
    const { userLanguage } = useTranslationContext('QuotedMessage');

    const Avatar = ContextAvatar || DefaultAvatar;

    const { quoted_message } = message;
    if (!quoted_message) return null;

    const quotedMessageText =
        quoted_message.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`] ||
        quoted_message.text;

    const quotedMessageAttachment = quoted_message?.attachments?.length
        ? quoted_message.attachments[0]
        : null;

    if (!quotedMessageText && !quotedMessageAttachment) return null;

    return (
        <>
            <div className={`${isMyMessage() ? 'quoted-message mine' : 'quoted-message'}`}>
                {quoted_message.user && (
                    <Avatar
                        image={quoted_message.user.image}
                        name={quoted_message.user.name || quoted_message.user.id}
                        size={20}
                        user={quoted_message.user}
                    >
                        <div style={{
                            backgroundColor: BlueColor.Dark,
                            color:'white',
                            width:'20',
                            textAlign: 'center',
                            lineHeight:'20',
                        }}>
                            {quoted_message.user?.name?.substring(0, 1)?.toUpperCase()}{quoted_message.user?.name?.split(' ')[1]?.substring(0, 1)?.toUpperCase()}
                        </div>
                    </Avatar>
                )}
                <div className='quoted-message-inner'>
                    {quotedMessageAttachment && <Attachment attachments={[quotedMessageAttachment]} />}
                    <div>{quotedMessageText}</div>
                </div>
            </div>
            {message.attachments?.length && message.quoted_message ? (
                <Attachment attachments={message.attachments} />
            ) : null}
        </>
    );
};
