import { makeStyles, Theme } from '@material-ui/core/styles';
import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';

export const useConversationStyles = makeStyles((theme: Theme) => ({
    saveConversationProgress: {
        float: 'right',
    },
    buttonActionTextEditor: {
        padding: "4px 16px"
    },
    toolbar: {
        '& .ql-editor': {
          minHeight: '6em'
        },
        '& .ql-snow.ql-toolbar': {
          display: 'block',
          background: '#eaecec',
          borderTopLeftRadius: '0.5em',
          borderTopRightRadius: '0.5em',
        },
        '& .ql-snow .ql-tooltip': {
            zIndex: 9
        },
        '& .ql-container': {
          borderBottomLeftRadius: '0.5em',
          borderBottomRightRadius: '0.5em',
          background: '#fefcfc'
        },
        '& .ql-mention-list-container':{
            maxHeight: 245
        }
    },
    error: {
        '& .ql-container': {
            '& .ql-editor': {
                border: '1px solid red',
                borderRadius: 0
            }
        }
    },
    storeFilter: {
        width: '100%',
        maxWidth: '205px',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 4
    },
    select: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        lineHeight: '24px',
    },
    dropzoneWrapper: {
        backgroundColor: 'transparent',
        outline: 'none',
    },
    filesList: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 0, 1, 0),
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        maxHeight: 300
    },
    actionsButtonComment: {
        padding: 0,
        color: BlueColor.DarkOld,
        width: 'auto',
        minWidth: 32,
        fontWeight: 500,
        height: 24,
        '&:hover':{
            color: BlueColor.DarkOld,
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            transitionDuration: '0s, 0.15s'
        },
        '& svg':{
            width: 16,
            height: 16,
        },
    },
    texCommentSingleMessage: {
        '& .ql-editor': {
            paddingRight: 0,
            paddingLeft: 0
        },
        '& .ql-snow.ql-toolbar': {
            display: 'block',
            background: UtilColors.White,
            border: 'none',
        },
        '& .ql-container': {
            background: UtilColors.White,
            border: 'none',
        },
        '& .ql-mention-list-container':{
            maxHeight: 245
        }
    },
    btnEmojiActive:{
        backgroundColor: GrayColor.GrayE3,
        '&:hover':{
            backgroundColor: GrayColor.GrayE3,
        }
    },
    btnReactions: {
        height: 28,
        minWidth: 45,
        backgroundColor: BlueColor.Blue5,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        border: '1px solid rgba(34, 100, 209, 0.5)',
        borderRadius: 24,
        cursor: 'pointer',
        '&:hover':{
            backgroundColor: BlueColor.Blue30,
            textDecoration: 'none',
        },
        '& p': {
            fontSize: 11,
            lineHeight: '14px',
            color: BlueColor.Blue80,
        },
        '& *':{
            cursor: 'pointer',
        },
    },
    emojiContainer:{
        position:'absolute',
        top: 25,
        display: 'flex',
        alignItems: 'center',
        height: 40,
        backgroundColor: UtilColors.White,
        border: '1px solid',
        borderRadius: 8,
        borderColor: GrayColor.GrayE3,
        zIndex: 10,
    }
}));
