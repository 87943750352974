import { Box } from "@material-ui/core";
import { DateRangeInput } from "components/basicInputs/dateRangeInput/DateRangeInput";
import { Formik, FormikProps } from "formik";
import { TASK_DATE_FORMAT_YYYYMMDD } from "modules/task/common/form/validation";
import { useCallback, useMemo } from "react";
import { firstDayOfTheMonth, todayMoment } from "../../../../../utils/helpers/dateHelper";
import { IViewDateRange, viewDateRangeSchema } from "./model";


const DateRangeForm = ({ handleSubmit }: FormikProps<any>) => (
        <form onSubmit={handleSubmit} data-testid="viewDateRangeForm">
            <Box>
                <DateRangeInput
                    name="startDate"
                    nameEnd="endDate"
                    label="Choose a date:"
                    onSelectDate={handleSubmit}
                    valueFormat={TASK_DATE_FORMAT_YYYYMMDD}
                />
            </Box>
        </form>
    );

export type ViewDateRangeProps = {
    onRangeDateChange: (newValue: IViewDateRange) => void;
};

export const ViewDateRange = ({onRangeDateChange}:ViewDateRangeProps) => {
    const initialFormValues = useMemo(
        () => ({
            startDate: firstDayOfTheMonth(),
            endDate: todayMoment()
        }),
        []
    );

    const onSubmit = useCallback((newValue: IViewDateRange) => {
        onRangeDateChange(newValue)
    }, [onRangeDateChange]);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={viewDateRangeSchema}
            onSubmit={onSubmit}
        >
            {(props) => <DateRangeForm {...props} />}
        </Formik>
    );
};
