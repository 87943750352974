import React from 'react';
import { Checkbox, IconButton, Paper, styled, Typography } from '@material-ui/core';
import { Location } from 'api/hierarchy/hierarchyModel';
import { usePickItemStyles } from 'modules/locationPicker/PickItem/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
}));

export type LocationItemProps = {
    readonly location: Location;
    canDeleteStore?: boolean;
    onDeleteStore: (storeId: string) => void;
}

export const LocationItem: React.FC<LocationItemProps> = ({ location, canDeleteStore, onDeleteStore }) => {
    const classes = usePickItemStyles();

    return (
        <StyledPaper>
            <Checkbox color="primary" checked disabled />
            <Typography className={classes.title}>{location.name}</Typography>
            { canDeleteStore && 
                <IconButton className={classes.actionBtnDelete} onClick={()=>onDeleteStore(location.id)}>
                    <DeleteIcon color="primary" />
                </IconButton>
            }
        </StyledPaper>
    );
}
