import React, { useMemo } from 'react';
import { StoreTaskFullModel, StoreTaskShort } from 'api/task/storeTask/storeTaskModel';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { StoreTaskChecklist } from 'modules/task/singleTask/components/StoreTaskChecklist';
import { fontFamily } from 'modules/root/theme';
import { TaskSubmit } from './TaskSubmit';
import { ParentTaskChildModel, ParentTaskDetailsModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { TaskApproveDecline } from './TaskApproveDecline';
import { TaskActivity } from './taskActivity/TaskActivity';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { Breakpoints } from 'utils/styles/constants';
import { TaskPercentageInline } from './TaskPercentageInline';
import { TaskSection } from './TaskSection';
import { AttachmentsGallery } from 'modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery';
import { UserInfo } from 'components/userInfo/UserInfo';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import ReactQuill from 'react-quill';
import { convertMarkdowntoHTML } from 'modules/connect/messenger/utils/customTextEditor';
interface ITaskSidebarContentProps {
    task?: StoreTaskFullModel | StoreTaskShort | ParentTaskDetailsModel | ParentTaskChildModel,
    isAutomationTask?: boolean
}

const useTaskSidebarContentStyles = makeStyles((theme: Theme) => ({
    infoItem: {
        '& + &': {
            marginTop: theme.spacing(4),
        },
    },
    descriptionBlock: {
        '& .ql-container': {
            border: 'none',
        },
        '& .ql-editor': {
            padding: 9,
            fontSize: 16
        }
    },
    editor: {
        fontFamily,
    },
}));

export function TaskSidebarContent({ task, isAutomationTask = false }: ITaskSidebarContentProps) {

    const classes = useTaskSidebarContentStyles([]);
    const storeTaskFull = task as StoreTaskFullModel | undefined;
    const taskDescription = useMemo(() => {
        const htmlDescription = storeTaskFull?.description
            ? convertMarkdowntoHTML(storeTaskFull?.description)
            : '';
        return htmlDescription;
    }, [storeTaskFull]);

    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const checklist = storeTaskFull?.checklistItems || [];
    const checklistTotal = checklist.length;
    const checklistCompleted = checklist.reduce((acc, item) => acc + Number(item.completed), 0);
    const taskAttchmentsCount = storeTaskFull?.prototypeAttachments?.length || 0;

    const taskSubmit = <TaskSubmit task={storeTaskFull} />;
    const taskApproveDecline = <TaskApproveDecline task={storeTaskFull} />;

    const watchers = storeTaskFull?.watchers || [];

    return (
        <Box display="flex" position="relative">
            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                maxWidth="100%"
            >
                {isMobile && (
                    <TaskPercentageInline
                        totalItems={checklistTotal}
                        completedItems={checklistCompleted}
                    />
                )}

                <TaskSection sectionTitle={<Trans>Description</Trans>}>
                    {storeTaskFull && typeof storeTaskFull.description === 'string' ? (
                        <ReactQuill
                            value={taskDescription}
                            modules={{"toolbar": false}}
                            readOnly
                            className={classes.descriptionBlock}
                        />
                    ) : (
                        <Skeleton width={200} />
                    )}
                </TaskSection>

                <TaskSection sectionTitle={`${t('Task Creator Attachments')} (${taskAttchmentsCount})`}>
                    {storeTaskFull && storeTaskFull.prototypeAttachments
                        ? (
                            <AttachmentsGallery attachments={storeTaskFull.prototypeAttachments} />
                        ) : (
                            <>
                                <Skeleton width={160} />
                                <Skeleton width={160} />
                            </>
                        )}
                </TaskSection>

                <TaskSection sectionTitle={`${t('Checklist')} (${checklistCompleted}/${checklistTotal})`}>
                    <StoreTaskChecklist task={storeTaskFull} isAutomationTask={isAutomationTask}/>
                </TaskSection>

                <TaskSection sectionTitle={`${t('Watchers')} (${watchers.length})`}>
                    <Box display="flex" flexWrap="wrap">
                        {storeTaskFull ?
                            watchers.map(user => {
                                return <Box pl={3} mt={1} key={user}><UserInfo userId={user} /></Box>
                            })
                            :
                            <Skeleton width={160} />
                        }
                    </Box>
                </TaskSection>

                <TaskActivity task={storeTaskFull} hideConversationTab={isAutomationTask}/>
                {taskSubmit}
                {taskApproveDecline}
                {(taskSubmit || taskApproveDecline) && (
                    <Box width="100%" height="80px" />
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
            />
        </Box>
    );
}
