import React, { useCallback, useEffect } from 'react';
import trim from 'lodash/trim';
import { Form, FormikProps } from 'formik';
import {
    Box,
    Button,
    Theme,
    makeStyles,
} from '@material-ui/core';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { EditChannelValidationSchema } from './model';
import { Sidebar } from 'components/sidebar/Sidebar';
import { CheckboxInput } from 'components/basicInputs/checkboxInput/CheckboxInput';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export interface IEditChannelDialogProps {
    isOpened: boolean;
    onClose: () => void;
    formik: FormikProps<EditChannelValidationSchema>;
    initialValues: EditChannelValidationSchema;
}

const useStyles = makeStyles((theme: Theme) => ({
    colorBase: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}));

export function EditChannelDialog({
    isOpened,
    onClose,
    initialValues,
    formik,
}: IEditChannelDialogProps) {
    const handleClose = useCallback(() => {
        formik.resetForm();
        onClose();
    }, [formik, onClose]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => formik.resetForm(), [isOpened]);
    const classes = useStyles();

    return (
        <Sidebar
            open={isOpened}
            onClose={onClose}
            title={<Trans>Edit channel</Trans>}
        >
            <Form>
                {renderBody({ colorBase: classes.colorBase })}

                {renderActions(handleClose, formik, initialValues, { btnAction: classes.colorBase })}
            </Form>
        </Sidebar>
    );
}

function renderBody(classes: { colorBase: string }) {

    return (
        <>
            <Box>
                <TextInput
                    label={`${t('Channel name')}`}
                    id="name"
                    name="name"
                    classes={{
                        label: classes.colorBase,
                    }}
                />
            </Box>

            <Box mt={1}>
                <TextInput
                    label={`${t('Description (optional)')}`}
                    id="description"
                    name="description"
                    multiline
                    classes={{
                        label: classes.colorBase,
                    }}
                />
            </Box>

            <Box mt={1}>
                <CheckboxInput
                    label={`${t('Freeze Channel')}`}
                    id="frozen"
                    name="frozen"
                />
            </Box>
        </>
    );
}

function renderActions(
    onClose: () => void,
    formik: FormikProps<EditChannelValidationSchema>,
    initialValues: EditChannelValidationSchema,
    classes: { btnAction: string }
) {
    const {
        name: initialName,
        description: initialDescription,
        frozen: initialFrozen = false,
    } = initialValues;
    const { name, description, frozen = false } = formik.values;
    const disabled = trim(name) === initialName && trim(description) === initialDescription && frozen === initialFrozen;

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            mt={1}
        >
            <Button className={classes.btnAction} type="submit" disabled={disabled}>
                <Trans>OK</Trans>
            </Button>
        </Box>
    );
}
