import { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box, IconButton, Theme, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Breakpoints } from 'utils/styles/constants';
import { Trans } from 'react-i18next';
const workerUrl = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100vw',
        height: 'auto',
        overflow: 'auto',
        [theme.breakpoints.up(Breakpoints.MobileLarge)]: {
            display: 'flex',
            justifyContent: 'center'
        },
    },
}))

export default function PDFViewer({ fileUrl } : { fileUrl: string } ) {
    const [numPages, setNumPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();

    useEffect(()=>{
        pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;
    },[])

    function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
        setNumPages(numPages);
    }

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };
    
    const previousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <Box className={classes.container}>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber}/>
            </Document>
            <Box position="absolute" bottom={0} width="100%" 
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={9}>
                <IconButton onClick={previousPage} disabled={pageNumber <= 1}>
                    <ArrowBackIosIcon color="primary" />
                </IconButton>
                <p>
                    <Trans i18nKey="textToPageNumberOfNumPages" pageNumber={pageNumber} numPages={numPages}>
                        Page {{pageNumber}} of {{numPages}}
                    </Trans>
                </p>
                <IconButton onClick={nextPage} disabled={pageNumber >= numPages}>
                    <ArrowForwardIosIcon color="primary" />
                </IconButton>
            </Box>
        </Box>
    );
}
