import { call, put, select, takeLatest } from 'typed-redux-saga';
import { requestSelfUserInfo } from 'api/user/userActions';
import { requestCompanyLocationsUserTree, requestHierarchy } from 'api/hierarchy/hierarchyActions';
import { storeLanguage } from 'modules/auth/storage';
import { UserDTO, getLanguage } from 'api/user/userModel';
import i18n from 'i18n';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from '../featureFlags/model';
import { selectFeatureFlags } from '../featureFlags/selectors';
import { FeatureFlags } from 'utils/featureFlags';
import { selectUser } from '../auth/authSelectors';

function* requestHierarchySaga() {
    yield takeLatest(
        requestSelfUserInfo.successType,
        function* ({ payload: user }: ReturnType<typeof requestSelfUserInfo.success> ) {
            const language = getLanguage(user?.language);
            i18n.changeLanguage(language);
            yield call(storeLanguage, language);
            yield put(requestHierarchy.init(user.companyId));

            const currentUser: UserDTO | null | undefined = yield* select(selectUser);
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const isTasksCalendarViewEnabled = featureFlags[FeatureFlags.TasksCalendarView]?.enabled;

            if(isTasksCalendarViewEnabled && currentUser?.isCompanyLocationStore === false){
                yield put(requestCompanyLocationsUserTree.init());
            }
        });
}

export const hierarchySagas = [
    requestHierarchySaga,
];
