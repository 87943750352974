import React, { useEffect, useMemo, useState, useContext, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectParentTasksFetching } from 'store/modules/task/parentTask/parentTaskSelectors';
import { SimpleTaskListHeader } from '../../components/SimpleTaskListHeader';
import { TaskListPageLayout } from '../../components/TaskListPageLayout';
import { ParentTasksGrid } from './grid/ParentTasksGrid';
import { useParentTasksGridData } from './grid/useParentTasksGridData';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useFixedSizeListHeight } from '../../hooks/useFixedSizeListHeight';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { RemoveParentTaskConfimation } from './RemoveParentTaskConfirmation';
import { TaskReviewSlideout } from 'modules/task/taskReview/TaskReviewSlideout';
import TasksToReviewButton from 'modules/task/taskReview/TasksToReviewButton';
import { useQueryParams } from 'utils/hooks';
import { generatePath, useHistory } from 'react-router';
import { Routes } from 'components/router/model';
import { taskReviewModalOpen } from 'store/modules/task/taskReview/taskReviewActions';
import { useActions } from "utils/store/useActions";
import { TaskQueryParams } from 'modules/task/common/model';
import { ViewSwitch } from '../../components/ViewSwitch/ViewSwitch';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { allTasksSyntheticValue, getAutomationTaskFilterOptions, getParentTaskFilterOptions } from '../../components/model';
import { ParentTaskStatusFilter } from 'api/task/parentTask/parentTaskModel';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { FlexBox } from 'modules/shared/components/FlexBox';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { TaskTaglistMultiselect } from 'modules/shared/components/TaskTaglistMultiselect';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { SearchTasks } from '../../components/SearchTasks';
import { TasksFilterContext } from '../../context/taskFilterContext';
import { TaskGallerySlideout } from 'modules/task/taskGallery/TaskGallerySlideout';
import { parentTaskGalleryModal, taskGalleryModalOpen } from 'store/modules/task/taskGallery/taskGalleryActions';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { AutomationTasksGrid } from '../../components/AutomationTaskGrid';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { ButtonSwitchType } from '../../components/ViewSwitch/TypeViewSwithMap';

const useStyles = makeStyles((theme: Theme) => ({
    statusFilter: {
        width: '100%',
        maxWidth: '200px',
    },
    select: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        lineHeight: '24px',
    },
}));

export const GlobalTasksView: React.FC = () => {
    const classes = useStyles();
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isExpirableColumnsFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.TaksExpirable];
    const isTasksSearchEnabled = useFeatureState(FeatureFlags.TasksSearch);
    const data = useParentTasksGridData();
    const [searchTasks, setSearchTasks] = useState<string>('');
    const areParentTasksFetching = useSelector(selectParentTasksFetching);
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();
    const currentUser = useCurrentUser();
    const { openTasksToReview, taskIdGallery } = useQueryParams<TaskQueryParams>();
    const typeTasksAppAccess = useSelector(selectHasAppAccess(AppName.Tasks)) ? ButtonSwitchType.ParentTasks : ButtonSwitchType.NoAccessTaskPage;
    const history = useHistory();
    const actions = useActions({
        openModal: taskReviewModalOpen,
        openGalleryModal: taskGalleryModalOpen,
        parentTaskGallery: parentTaskGalleryModal,
    });
    const { 
        valuesFiltersTasks,
        handleFilterTag,
        handleSetFilterStatus,
        showFilterTag,
        showTableTask
    } = useContext(TasksFilterContext);
    const options = useMemo(() => {
        return valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ? getParentTaskFilterOptions(isOverdueColumnsFeature?.enabled ?? false, isExpirableColumnsFeatureEnabled?.enabled ?? false)
        : getAutomationTaskFilterOptions()
    }, [isOverdueColumnsFeature, isExpirableColumnsFeatureEnabled, valuesFiltersTasks.tasksViewMode]);

    const header = useMemo(() => {
        const simpleHeader = <SimpleTaskListHeader regionName={currentUser?.companyLocationName ?? ''} />;

        if (!canCreateTask) {
            return simpleHeader;
        }

        return (
            <Box display="flex" justifyContent="space-between">
                {simpleHeader}
                <TasksToReviewButton />
            </Box>
        );
    }, [currentUser, canCreateTask]);

    const subheader = useMemo(() => {
        return (
            <FlexBox flexWrap="wrap" width="100%" justifyContent="space-between" mt={1}>
                <FlexBox flexWrap="wrap" width={{xs: '100%', sm: '100%', md:'100%',lg:'calc(100% - 243px)'}}>
                    <ViewSwitch type={typeTasksAppAccess} />
                    <OptionsDropdown
                        className={classes.statusFilter}
                        selectClasses={{ select: classes.select }}
                        unsetValue={allTasksSyntheticValue}
                        options={options}
                        value={valuesFiltersTasks.filter}
                        onValueChange={handleSetFilterStatus}
                    />
                    {showFilterTag && <TaskTaglistMultiselect 
                        onTagsValuesChange={handleFilterTag}
                        initialTagSelected={valuesFiltersTasks.tags}
                    />}
                </FlexBox>
                {isTasksSearchEnabled && <SearchTasks 
                    onSearchTaskValuesChange={setSearchTasks}
                />}
            </FlexBox>
        );
    }, [classes, handleSetFilterStatus, options, isTasksSearchEnabled, handleFilterTag, showFilterTag, valuesFiltersTasks, typeTasksAppAccess]);

    useEffect(() => {
        if (openTasksToReview) {
            actions.current.openModal();
            history.replace(generatePath(Routes.TaskList));
        }
    }, [actions, history, openTasksToReview]);

    useLayoutEffect(()=>{
        if(taskIdGallery){
            actions.current.parentTaskGallery(taskIdGallery);
            actions.current.openGalleryModal();
        }
    }, [actions, taskIdGallery]);

    return (
        <>
            <TaskListPageLayout
                header={<>
                    {header}
                    {subheader}
                </>}
                layoutHeaderRef={layoutHeaderRef}
                showCreateButton={canCreateTask}
            >
                { showTableTask &&
                <>
                    { 
                        valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ?
                            <ParentTasksGrid
                                data={data}
                                isFetching={areParentTasksFetching}
                                filter={valuesFiltersTasks.filter as ParentTaskStatusFilter | undefined}
                                filterTag={valuesFiltersTasks.tags}
                                height={gridHeight}
                                searchTerm={searchTasks}
                                tasksViewMode={valuesFiltersTasks.tasksViewMode}
                            />
                            :<>
                                <AutomationTasksGrid
                                    filter={valuesFiltersTasks.filter as AutomationTaskStatusFilter | undefined}
                                    filterTag={valuesFiltersTasks.tags}
                                    height={gridHeight}
                                    searchTerm={searchTasks}
                                />
                                <StoreTaskView />
                            </>
                    }
                </>
                } 
            </TaskListPageLayout>

            <TaskReviewSlideout />
            <TaskGallerySlideout />
            <RemoveParentTaskConfimation />
        </>
    );
};
