import { Quill } from "react-quill";

const Inline = Quill.import('blots/inline');

export class LinkBlot extends Inline {
  static create(value: any) {
    let node = super.create();

    if (!/^(https?:)?\/\//.test(value)) {
      value = `https://${value}`;
    }
    node.setAttribute("href", value);
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");

    return node;
  }

  static formats(node: any) {
    return node.getAttribute("href");
  }
}

LinkBlot.blotName = "link";
LinkBlot.tagName = "a";