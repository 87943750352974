import clsx from 'clsx';
import { StreamChatType } from 'modules/connect/messenger/components/MessageInput/hooks/types';
import React, { useMemo, useRef } from 'react';
import type { TranslationLanguages } from 'stream-chat';
import {
  areMessageUIPropsEqual,
  Avatar,
  ErrorIcon,
  isOnlyEmojis,
  MessageContextValue,
  MessageRepliesCountButton,
  MessageStatus,
  MessageTimestamp,
  MessageUIComponentProps,
  renderText as defaultRenderText,
  SimpleReactionsList,
  useComponentContext,
  useMessageContext,
  useReactionClick,
  useTranslationContext,
  PinIndicator,
  useChannelActionContext,
} from 'stream-chat-react';
import { useWorkspaceController } from '../WorkspaceController';
import InputIcon from '@material-ui/icons/Input';
import { Fade, Tooltip } from '@material-ui/core';
import { Trans } from 'react-i18next';

type MessageTeamWithContextProps = MessageContextValue<StreamChatType> & {
  isReactionEnabled: boolean;
  messageWrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  onJumpToMessage: () => void;
};

const MessageTeamWithContext = (
  props: MessageTeamWithContextProps,
) => {
  const {
    groupStyles,
    handleAction,
    handleOpenThread,
    handleRetry,
    initialMessage,
    isReactionEnabled,
    message,
    messageWrapperRef,
    onUserClick,
    renderText = defaultRenderText,
    threadList,
    onJumpToMessage
  } = props;

  const { Attachment } = useComponentContext<StreamChatType>('MessageTeam');

  const { t, userLanguage } = useTranslationContext('MessageTeam');

  const messageTextToRender =
    message.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`] || message.text;

  const messageMentionedUsersItem = message.mentioned_users;

  const messageText = useMemo(() => renderText(messageTextToRender, messageMentionedUsersItem), [
    messageMentionedUsersItem,
    messageTextToRender,
    renderText,
  ]);

  const firstGroupStyle = groupStyles ? groupStyles[0] : 'single';

  const rootClass = clsx(
    'str-chat__message',
    'str-chat__message-team',
    `str-chat__message-team--${firstGroupStyle}`,
    {
      'pinned-message': message.pinned,
      [`str-chat__message-team--${message.status}`]: message.status,
      [`str-chat__message-team--${message.type}`]: message.type,
      'str-chat__message--has-attachment': !!message.attachments?.length,
      'threadList': threadList,
    },
  );

  return (
    <>
      {message.pinned && <PinIndicator message={message} />}
      <div
        className={rootClass}
        data-testid='message-team'
        ref={messageWrapperRef}
      >
        <div className='avatar-host'>
          {firstGroupStyle === 'top' || firstGroupStyle === 'single' || initialMessage ? (
            <Avatar
              image={message.user?.image}
              name={message.user?.name || message.user?.id}
              onClick={onUserClick}
              size={34}
            />
          ) : (
            <div data-testid='team-meta-spacer' style={{ marginRight: 0, width: 34 }} />
          )}
        </div>
        <div className='str-chat__message-team-group'>
          {(firstGroupStyle === 'top' || firstGroupStyle === 'single' || initialMessage) && (
            <div className='str-chat__message-team-meta'>
              <div
                className='str-chat__message-team-author'
                data-testid='message-team-author'
              >
                <strong>{message.user?.name || message.user?.id}</strong>
                {message.type === 'error' && (
                  <div className='str-chat__message-team-error-header'>
                    {t<string>('Only visible to you')}
                  </div>
                )}
              </div>
              <div className='str-chat__message-team-meta__time-jump'>
                <MessageTimestamp />
                <Tooltip
                  title={<Trans>Go to the message</Trans>} arrow 
                  placement='top' 
                  TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                  <InputIcon color="primary" onClick={onJumpToMessage} className='str-chat__message-team-meta__time-jump--jump' fontSize='small'/>
                </Tooltip>
              </div>
            </div>
          )}
          <div
            className={`str-chat__message-team-content str-chat__message-team-content--${firstGroupStyle} str-chat__message-team-content--${
              message.text === '' ? 'image' : 'text'
            }`}
            data-testid='message-team-content'
          >
            {message.text && (<div
                className={clsx('str-chat__message-team-text', {'str-chat__message-team-text--is-emoji': isOnlyEmojis(message.text)})}
                data-testid='message-team-message'
              >
                {messageText}
              </div>
            )}
            {!message.text && message.attachments?.length ? (
              <Attachment actionHandler={handleAction} attachments={message.attachments} />
            ) : null}
            {message.latest_reactions?.length !== 0 && message.text !== '' && isReactionEnabled && (
              <SimpleReactionsList />
            )}
            {message.status === 'failed' && (
              <button
                className='str-chat__message-team-failed'
                data-testid='message-team-failed'
                onClick={message.errorStatusCode !== 403 ? () => handleRetry(message) : undefined}
              >
                <ErrorIcon />
                {message.errorStatusCode !== 403
                  ? t<string>('Message Failed · Click to try again')
                  : t<string>('Message Failed · Unauthorized')}
              </button>
            )}
          </div>
          <MessageStatus messageType='team' />
          {message.text && message.attachments?.length ? (
            <Attachment actionHandler={handleAction} attachments={message.attachments} />
          ) : null}
          {message.latest_reactions &&
            message.latest_reactions.length !== 0 &&
            message.text === '' &&
            isReactionEnabled && <SimpleReactionsList />}
          {!threadList && (
            <MessageRepliesCountButton
              onClick={handleOpenThread}
              reply_count={message.reply_count}
            />
          )}
        </div>
      </div>
    </>
  );
};

const MemoizedMessageTeam = React.memo(
  MessageTeamWithContext,
  areMessageUIPropsEqual,
) as typeof MessageTeamWithContext;


export const TeamMessage = (
  props: MessageUIComponentProps<StreamChatType>,
) => {
  const messageContext = useMessageContext<StreamChatType>('MessageTeam');
  const { closePinnedMessageListOpen } = useWorkspaceController();

  const reactionSelectorRef = useRef<HTMLDivElement | null>(null);
  const messageWrapperRef = useRef<HTMLDivElement | null>(null);

  const message = props.message || messageContext.message;

  const { isReactionEnabled, onReactionListClick, showDetailedReactions } = useReactionClick(
    message,
    reactionSelectorRef,
    messageWrapperRef,
  );
  const { jumpToMessage } = useChannelActionContext();

  const handleOpenThreadOverride = (event: React.BaseSyntheticEvent) => {
    closePinnedMessageListOpen();
    messageContext.handleOpenThread(event);
  };

  const handleJumpToMessage = () => {
    jumpToMessage(message.id);
    closePinnedMessageListOpen();
  }

  return (
    <div className={message.pinned ? 'pinned-message' : 'unpinned-message'}>
      <MemoizedMessageTeam
        {...messageContext}
        isReactionEnabled={isReactionEnabled}
        messageWrapperRef={messageWrapperRef}
        onReactionListClick={onReactionListClick}
        reactionSelectorRef={reactionSelectorRef}
        showDetailedReactions={showDetailedReactions}
        handleOpenThread={handleOpenThreadOverride}
        onJumpToMessage={handleJumpToMessage}
        {...props}
      />
    </div>
  );
};
