import React, { useCallback, useMemo } from 'react';
import { Box, Fade, IconButton, TextField, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { useActions } from 'utils/store/useActions';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
interface IStoreTaskReadonlyChecklistCustomInputProps {
    customResponse?: string | null,
}

export const StoreTaskReadonlyChecklistCustomInput = ({ customResponse }: IStoreTaskReadonlyChecklistCustomInputProps) => {
    const actions = useActions({
        showMessage: showToastMessage,
    });
    const handleCopy = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(customResponse || '');
        actions.current.showMessage({
            message: `${t('Copied content')}: "${customResponse}"`, options: { variant: ToastVariant.Success, autoHideDuration: 2000 },
        })
    },[customResponse, actions]);

    const adornment = useMemo(()=>(
        <Tooltip title={<Trans>Copy</Trans>} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <IconButton aria-label="Copy" onClick={(e) => handleCopy(e)}>
                <FileCopyIcon color="inherit"/>
            </IconButton>
        </Tooltip>
    ),[handleCopy]);

    return (<Box ml={3} width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <TextField
            disabled
            value={customResponse || ''}
            variant="outlined"
            aria-describedby={`${t('custom-response-description')}`}
            fullWidth
            InputProps={{
                endAdornment: adornment
            }}
        />
    </Box>)
};
