import React from 'react'
import { useBulkTasksStyles } from './useBulkTasksStyles';
import { Fade, Tooltip, Typography } from '@material-ui/core';

export const TaskBulkHistoryFileName: React.FC<{ fileName: string}> = ({ fileName }) => {
  const classes = useBulkTasksStyles();

  return (
    <Tooltip title={fileName} arrow placement='top' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
      <Typography variant='body2'className={classes.tableFileName}>{fileName}</Typography>
    </Tooltip>
  )
}
