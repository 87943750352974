import { TasksRangeDate } from 'api/task/statistics/statisticsModel';
import { createSingleAction } from 'utils/store/actionUtils';

export const RESET_TASKS_STATISTICS = 'RESET_TASKS_STATISTICS';
export const resetTasksStatistics = createSingleAction<void, typeof RESET_TASKS_STATISTICS>(RESET_TASKS_STATISTICS);

export const GET_TASKS_STATISTICS = 'GET_TASKS_STATISTICS';
export const requestTasksStatistics = createSingleAction<{locationId: string, endDate?:string, startDate?: string, tags: string[]}, typeof GET_TASKS_STATISTICS>(GET_TASKS_STATISTICS);

export const TASKS_RANGE_DATE = 'TASKS_RANGE_DATE';
export const setTaskRangeDate = createSingleAction<
    TasksRangeDate, typeof TASKS_RANGE_DATE
>(
    TASKS_RANGE_DATE,
);

export const RESET_TASKS_RANGE_DATE = 'RESET_TASKS_RANGE_DATE';
export const resetTaskRangeDate = createSingleAction< void, typeof RESET_TASKS_RANGE_DATE>(
    RESET_TASKS_RANGE_DATE
);

export const EMPTY_TASKS_RANGE_DATE = 'EMPTY_TASKS_RANGE_DATE';
export const emptyTaskRangeDate = createSingleAction< void, typeof EMPTY_TASKS_RANGE_DATE>(
    EMPTY_TASKS_RANGE_DATE
);


export const TASKS_TAGS = 'TASKS_TAGS';
export const setTaskTags = createSingleAction<
    string[], typeof TASKS_TAGS
>(
    TASKS_TAGS,
);

export const RESET_TASKS_TAGS = 'RESET_TASKS_TASKS_TAGS';
export const resetTasksTags = createSingleAction< void, typeof RESET_TASKS_TAGS>(
    RESET_TASKS_TAGS
);

export type TasksStatisticsActions =
    | ReturnType<typeof resetTasksStatistics>
    | ReturnType<typeof requestTasksStatistics>
    | ReturnType<typeof setTaskRangeDate>
    | ReturnType<typeof resetTaskRangeDate>
    | ReturnType<typeof emptyTaskRangeDate>
    | ReturnType<typeof setTaskTags>
    | ReturnType<typeof resetTasksTags>;
