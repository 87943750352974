import React from 'react';
import { IconButton } from '@material-ui/core';
import { VisibilityOff } from '@material-ui/icons';
import { useHideActiveChat } from '../../../../hooks/useHideActiveChat';

export function HideAction() {
    const { hideActiveChat } = useHideActiveChat();

    return (
        <IconButton onClick={hideActiveChat}>
            <VisibilityOff color="primary" />
        </IconButton>
    );
}
