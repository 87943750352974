import { Dialog, Paper, styled } from '@material-ui/core';
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { CustomControls } from './CustomControls';
import noPreviewImageUrl from 'images/no-preview.png';

import 'react-image-gallery/styles/css/image-gallery.css';
import './lightbox.scss';

const LightboxPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.common.black,
}));

export type LightboxProps = {
    items: ReactImageGalleryItem[];
    apiRef: MutableRefObject<LightboxApi | undefined>;
}

export type LightboxApi = {
    open: (index: number) => void;
}

export type LightboxItem = ReactImageGalleryItem;

const options: Partial<ReactImageGalleryProps> = {
    lazyLoad: true,
    showPlayButton: false,
    showIndex: true,
    showFullscreenButton: false,
    onErrorImageURL: noPreviewImageUrl,
};

export const Lightbox = ({ items, apiRef }: LightboxProps) => {
    const [open, setOpen] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    const galleryRef = useRef<ImageGallery>();
    const componentRef = useCallback((ref: ImageGallery) => {
        galleryRef.current = ref;
    }, []);

    const closeHandler = useCallback(() => setOpen(false), []);
    const fullscreenToggleHandler = useCallback(() => {
        if (fullscreen) {
            galleryRef.current?.exitFullScreen();
        } else {
            galleryRef.current?.fullScreen();
        }
    }, [galleryRef, fullscreen]);

    useEffect(() => {
        apiRef.current = {
            open: index => {
                setOpen(true);
                setTimeout(() => galleryRef.current?.slideToIndex(index));
            },
        };
    }, [apiRef, galleryRef]);

    if (!items.length) {
        return null;
    }

    return (
        <Dialog
            className='lightbox'
            open={open}
            fullScreen
            PaperComponent={LightboxPaper}
        >
            <ImageGallery
                ref={componentRef}
                onScreenChange={setFullscreen}
                items={items}
                renderCustomControls={() => (
                    <CustomControls
                        isFullscreen={fullscreen}
                        closeLightboxHandler={closeHandler}
                        fullscreenToggleHandler={fullscreenToggleHandler}
                    />
                )}
                {...options}
            />
        </Dialog>
    );
};
