import { CellProps } from "react-table";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Location } from "api/hierarchy/hierarchyModel";
import { useSelector } from "react-redux";
import {
    selectTasksStatsByLocationIds,
    selectTasksStatsLoadingByLocationIds,
} from 'store/modules/task/statistics/statisticsSelectors';

export type TaskCounterCellProps = CellProps<Location, string>;

export const TaskCounterCell = (props: TaskCounterCellProps) => {
    const locationId = props.row.values.locationId;
    const tasksStatistics = useSelector(selectTasksStatsByLocationIds)[locationId];
    type StatsKey = keyof typeof tasksStatistics;
    const isTasksStatisticsLoading = useSelector(selectTasksStatsLoadingByLocationIds)[locationId];
    const key = props.column.id as StatsKey;
    const statsValue = tasksStatistics ? tasksStatistics[key] : "0";

    if (isTasksStatisticsLoading || !tasksStatistics) {
        return (
            <Box>
                <Skeleton width="60%" />
                <Skeleton />
            </Box>
        )
    }

    return (
        <Box>
            {statsValue}
        </Box>
    );
};
