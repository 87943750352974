import React, { useCallback } from 'react';
import { IconButton, Theme, useMediaQuery } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Breakpoints } from 'utils/styles/constants';
import { useDeleteConversation } from 'modules/connect/messenger/hooks/useDeleteConversation';
import { useButtonsStyles } from './styles';

export interface IDeleteConversationButtonProps {
    conversationId: string;
}

export function DeleteConversationButton({ conversationId }: IDeleteConversationButtonProps) {
    const classes = useButtonsStyles();
    const { deleteConversation, isChannelDeleting } = useDeleteConversation(conversationId);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge));

    const handleDeleteClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        deleteConversation?.();

        event.preventDefault();
        event.stopPropagation();
    }, [deleteConversation]);

    if (!deleteConversation || isMobile) {
        return null;
    }

    return (
        <IconButton
            disabled={isChannelDeleting}
            className={classes.root}
            onClick={handleDeleteClick}
        >
            <CloseIcon />
        </IconButton>
    );
}
