import { Theme, alpha, makeStyles } from "@material-ui/core";
import { UtilColors } from "utils/styles/constants";

export const useBulkTasksStyles = makeStyles((theme: Theme) => ({
  header: {
      flexDirection: 'column',
      marginLeft: 0,
      '& a':{
        border: `1px solid ${theme.palette.getContrastText(theme.palette.primary.dark)}`,
        borderColor: alpha(theme.palette.getContrastText(theme.palette.primary.dark), 0.5),
        color: theme.palette.getContrastText(theme.palette.primary.dark),
  
        '&:hover': {
            backgroundColor: UtilColors.Transparent,
            borderColor: theme.palette.getContrastText(theme.palette.primary.dark),
            color: theme.palette.getContrastText(theme.palette.primary.dark),
        },
      }
  },
  containerFile: {
      '& svg': {
        fill: theme.palette.getContrastText(theme.palette.primary.dark),
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      },
      '& h6':{
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      }
  },
  resetProgress:{
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  container: {
    paddingRight: 2
  },
  rootPaper: {
    width: '100%',
    backgroundColor: 'transparent'
  },
  historyTableContainer: {
    maxHeight: 'calc(100vh - 460px)',
    minHeight: 100
  },
  tableHistoryBody: {
  },
  tableHistoryCell: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    padding: 8,
  },
  tableSkeleton: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    marginTop: 10,
    marginBottom: 15,
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.dark),
    '&:after': {
      background: `linear-gradient(90deg, transparent, ${alpha(theme.palette.primary.dark, 0.4)}, transparent)`
    }
  },
  tableHeader: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    backgroundColor: theme.palette.primary.dark,
    fontWeight: 600,
    textWrap: 'nowrap',
    padding: '16px 8px'
  },
  tableFileName: {
    width: 68, 
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
  },
}));
