import { Theme, makeStyles } from "@material-ui/core";
import { EmojiData, emojiIndex } from "emoji-mart";
import ReactQuill from "react-quill";
import showdown from "showdown";
import { Breakpoints } from "utils/styles/constants";

export const markdownConverter = new showdown.Converter({
    noHeaderId: true,
    parseImgDimensions: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tables: true,
    tasklists: true,
    ghCodeBlocks: true,
    openLinksInNewWindow: true,
    emoji: true,
    underline: true,
    simpleLineBreaks: true,
    literalMidWordUnderscores: true,
});

export const maxLengthCharacters = 5000;

export const linkListOptions = ['link']
export const bulletsFormatOptions = 'list';
export const bulletsOptions = [{'list': 'bullet'}, {'list': 'ordered'}]

export const toolbarFormatsOptions = ['bold', 'italic', 'code']

const emojiReg = /[;|:].\n\n/;

export const giphy = "/giphy ";

export const isEndEmojiString = (value: string) => emojiReg.exec(value);

export const emojiReplace = (word: string) => {
    const found = emojiIndex?.search(word) || [];
    const emoji = found
        .filter(Boolean)
        .slice(0, 10)
        .find(({ emoticons }: EmojiData) => !!emoticons?.includes(word));
    if (!emoji || !('native' in emoji)) return null;
    return emoji.native;
};
export const getNewCursorPosition = (newValue: string, markdownContentLength: number, caretPosition: number) => newValue.length - (markdownContentLength - 2 - caretPosition);

export const getLastWord = (markdownContent: string, caretPosition: number, newValue: string) => {
    if(giphy === newValue) return newValue;
    const match = markdownContent.trim().slice(0, caretPosition).split(' ');
    return match[match.length -1 ] !== '' ? match[match.length -1 ] : null;
}

export const useCustomTextEditorStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        '& .ql-toolbar': {
            position: 'absolute',
            bottom: theme.spacing(4),
            left: theme.spacing(11.5),
            border: 'none',
            padding: theme.spacing(0.625, 0),
            zIndex: 1,
            [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
                left: theme.spacing(9),
            },
            '& .ql-formats': {
                marginRight: 0
            }
        },
        '& .ql-container': {
            border: 0,
            maxHeight: 200,
            overflowY: 'auto',
            position: 'initial',
            '& .ql-editor': {
                maxHeight: 200,
                position: 'relative'
            },
            '& .ql-editing, .ql-tooltip': {
                zIndex: 1,
                [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
                    left: '0!important',
                    transform: 'translateY(10px)'
                },
            }
        }
    },
    error: {
        '& .ql-container': {
            '& .ql-editor': {
                border: '1px solid red',
                borderRadius: '8px 0px 0px'
            }
        }
    },
    inputCounter: {
        position: 'absolute',
        right: 45,
        bottom: 32,
        padding: '5px 0px',
        zIndex: 1,
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            fontSize: '0.6rem',
            right: 25
        },
    }
}));

export const hasEditIntheMiddle = (editor: ReactQuill.UnprivilegedEditor) => {
    return (editor.getSelection()?.index || 0) < editor.getText().trim().length;
}

const validURL = (url: string) => /^https?:\/\//.test(url);

const extractFormatsFromContent = (content: any, currentPosition: number): any[] => {
    const delta: any = content.slice(0, currentPosition + 1);
    return delta.ops.map((op: any) => ({insert: op.insert, attributes: op.attributes}));
}

const calculateCounterAndLineBreak = (formats: any[]): { counter: number; lineBreak: number } => {
    let counter = 0;
    let lineBreak = 0;

    formats.forEach((element: any) => {
        const attr = element?.attributes;
        if (attr) {
            if (attr.bold) {
                counter += 4;
            }
            if (attr.italic || attr.code || attr.list) {
                counter += 2;
            }
            if (attr.link) {
                const link = !validURL(attr.link) ? attr.link.length + 8 : attr.link.length;
                const url = link + 4;
                counter += url;
            }
        }

        const lineBreakAux = element?.insert?.split('\n').length;
        const lineBreakDoubleAux = element?.insert?.split('\n \n').length;
        if (lineBreakAux > 1) {
            lineBreak += (lineBreakAux - 1) + (lineBreakDoubleAux - 1);
        }
    });

    return { counter, lineBreak };
};

export const getCursorPositionByFormat = (editor: any, currentPosition = 0): number => {
    const content = editor?.getContents();
    if(!content) return currentPosition;

    const formats = extractFormatsFromContent(content, currentPosition);
    const { counter, lineBreak } = calculateCounterAndLineBreak(formats);

    return currentPosition + counter + lineBreak;
}

export const convertHTMLtoMarkdownGetStream = (html: string, isConnectFormatHyperlinkEnabled: boolean ) => {
    const markdown = markdownConverter.makeMarkdown(html);
    return !isConnectFormatHyperlinkEnabled ? markdown
        :  markdown.replace(/]\(<([^>]+)>\)/g, (match, p1, ) => {
            if (!validURL(p1)) {
                p1 = `https://${p1}`;
            }
            return `](${p1})`;
        });
}

export const removeEmptyBlockComments = (text: string, isConnectFormatBulletEnabled: boolean) => {
    return !isConnectFormatBulletEnabled ? text : text.replaceAll("<!-- -->\n\n", '');
}

export const removeEmptyBlockBullets = (html: string, isConnectFormatBulletEnabled: boolean) => {
    return !isConnectFormatBulletEnabled ? html.replaceAll('<br />\n','<br />') 
        : html.replaceAll('<br />\n','<br />')
            .replaceAll('<ul>\n<li>','<ul><li>')
            .replaceAll('</li>\n</ul>','</li></ul>')
            .replaceAll('<ol>\n<li>','<ol><li>')
            .replaceAll('</li>\n</ol>','</li></ol>')
            .replaceAll('</li>\n<li>', '</li><li>');
}

export const convertMarkdowntoHTML = (textMarkdown: string) => {
    const markdownFormat = convertHTMLtoMarkdownGetStream(markdownConverter.makeHtml(textMarkdown), true);
    return markdownConverter.makeHtml(markdownFormat);
}
