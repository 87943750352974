import React from 'react';
import { StoreTaskAttachmentModel } from 'api/task/storeTask/storeTaskModel';
import { AttachmentsGallery } from 'modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery';
import { Box } from '@material-ui/core';

interface IStoreTaskReadonlyChecklistFilesProps {
    attachments: StoreTaskAttachmentModel[],
}

export function StoreTaskReadonlyChecklistFiles({ attachments }: IStoreTaskReadonlyChecklistFilesProps) {
    if (!attachments.length) {
        return null;
    }

    return (
        <Box ml={5}>
            <AttachmentsGallery attachments={attachments}/>
        </Box>
    );
}
