import { put, select, takeEvery } from 'typed-redux-saga';
import { GET_TASKS_STATISTICS, requestTasksStatistics } from './statisticsActions';
import { selectTasksStatsByLocationIds, selectTasksStatsLoadingByLocationIds } from './statisticsSelectors';
import { requestTasksStatsByLocation } from 'api/task/statistics/statisticsActions';
import type { ItemsById } from 'model/entity';
import type { TasksStatistics } from 'api/task/statistics/statisticsModel';

export function* getTasksStatisticsWatcherSaga() {
    yield takeEvery(
        GET_TASKS_STATISTICS,
        function* ({ payload: {locationId, endDate, startDate, tags} }: ReturnType<typeof requestTasksStatistics>) {
            const loadingById: ItemsById<boolean> = yield select(selectTasksStatsLoadingByLocationIds);
            const statsById: ItemsById<TasksStatistics> = yield select(selectTasksStatsByLocationIds);

            if (!loadingById[locationId] && !statsById[locationId]) {
                yield put(requestTasksStatsByLocation.init({
                    locationId,
                    endDate,
                    startDate,
                    tags,
                }));
            }
        },
    );
}

export const statisticsSagas = [
    getTasksStatisticsWatcherSaga,
];
