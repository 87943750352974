import { Box, CircularProgress, makeStyles, TextField, Theme } from '@material-ui/core';
import { Breakpoints } from '../../../../../utils/styles/constants';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import { Channel, StreamChat } from 'stream-chat';
import { useDispatch } from 'react-redux';
import { ISearchResult, ISearchResultData, ISearchResultMessage } from '../../../../../api/getStream/getStreamModel';
import { AutocompleteChatMessage } from './AutocompleteChatMessage';
import debounce from 'lodash/debounce';
import { requestChatMsgSearch } from '../../../../../api/getStream/getStreamActions';
import { useChannelActionContext } from 'stream-chat-react';
import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: theme.spacing(60),
        [theme.breakpoints.down(1114)]: {
            width: '40vw'
        },
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            width: '70vw',
        },
    },
}));

export interface ISearchMessagesContainerProps {
    client: StreamChat;
    channel?: Channel<StreamChatGenerics>;
}

export const maxDisplayedLength = 256;

export const SelectChatMessage = ({ client, channel }: ISearchMessagesContainerProps) => {
    const [inputValue, setInputValue] = useState('');
    const dispatch = useDispatch();
    const classes = useStyles();
    const { jumpToMessage } = useChannelActionContext();

    const [foundMessages, setFoundMessages] = useState<Array<ISearchResultMessage>>([]);
    const [isGettingMessages, setIsGettingMessages] = useState(false);

    const getMessageOptionLabel = useCallback((message: ISearchResultMessage) => {
        if ((message?.text ?? '').length > maxDisplayedLength) {
            return message.text.substring(0, maxDisplayedLength) + '...';
        } else {
            return message.text;
        }
    },[]);

    const renderOption = useCallback((message: ISearchResultMessage) => {
        return (
            <AutocompleteChatMessage {...message}/>
        );
    },[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getMessages = useCallback(debounce(async (value: any) => {
        if (!channel?.id) {
            return;
        }
        setIsGettingMessages(true);
        const channelFilter = {
            cid: {
                $eq: channel?.cid ?? ''
            }};
        const messageFilter = {
            text: { $autocomplete: value}
        };
        const options = {
            limit: 10,
            offset: 0
        };
        await client.search(channelFilter, messageFilter, options)
            .then(({results}) => results.map(x => x.message as unknown as ISearchResultMessage))
            .then((x) => {
                setFoundMessages(x);
                const payload = {
                    results: x.map(y => {
                        return {message: y} as ISearchResult;
                    }),
                } as ISearchResultData;
                dispatch(requestChatMsgSearch.success(payload));
            })
            .finally(() => setIsGettingMessages(false));
    }, 300), [client, channel, setIsGettingMessages, dispatch]);

    const handleInputChange = useCallback((event: any) => {
        setInputValue(event.target.value)
        if (!event?.target?.value?.trim()) {
            return;
        }
        getMessages(event.target.value);
    }, [getMessages]);

    const handleSelectorChange = useCallback(async (event, messages, reason) => {
        if (messages === null || reason === 'clear') {
            setInputValue("")
            return;
        }
        const messageId = messages.id
        await jumpToMessage(messageId);
        setFoundMessages([]);
    }, [jumpToMessage]);

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        const adornment = (
            <React.Fragment>
                {isGettingMessages ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
            </React.Fragment>
        );
        return (
            <TextField
                {...params}
                label={<Trans>Search chat messages</Trans>}
                placeholder={`${t('Start typing')}...`}
                variant="outlined"
                onChange={handleInputChange}
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: adornment,
                }}
                size="small"
            />
        );
    }, [handleInputChange, isGettingMessages]);

    return (
        <Box className={classes.container}>
            <Autocomplete
                fullWidth
                options={foundMessages}
                getOptionLabel={getMessageOptionLabel}
                renderOption={renderOption}
                renderInput={renderInput}
                onChange={handleSelectorChange}
                autoHighlight
                noOptionsText={<Trans>No Results</Trans>}
                onClose={() => setFoundMessages([])}
                inputValue={inputValue}
                ListboxProps={{ style: { maxHeight: '35rem' } }}
                onBlur={() => setInputValue("")}
            />
        </Box>
    );
}
