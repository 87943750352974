import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Channel, User, UserResponse } from 'stream-chat';
import { Group as GroupIcon } from '@material-ui/icons';
import { Box, CircularProgress, List, makeStyles, Switch, TextField, Theme, Typography } from '@material-ui/core';
import { ChannelDetailsSection } from './ChannelDetailsSection';
import { searchMembers, sortByOwner, filterDeactivatedMembers } from '../../../../../helpers';
import { IAccordionSection } from './model';
import { ChannelMember } from './ChannelMember';
import { useActions } from "utils/store/useActions";
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { useSelector } from 'react-redux';
import { selectUserRoles, selectUsersByIds } from 'store/modules/user/userSelectors';
import { selectChatRoleIds } from '../../../../../../../../store/modules/getStream/getStreamSelectors';
import { UserRoleDTO } from '../../../../../../../../api/core/model';
import { ChannelRoleMember } from './ChannelRoleMember';
import { UserPermissionChannel } from 'api/getStream/getStreamModel';
import { debounce, uniqBy } from 'lodash';
import { Scrollbar } from 'components/containers/Scrollbar';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) => ({
    list: {
        width: '100%',
    },
}));
export interface IChannelMembersProps extends IAccordionSection {
    channel: Channel;
}

const isMultiplo100 = (size: number)=>(size > 0 && size % 100 === 0 ? `+${size}` : `${size}`)

export function ChannelMembers({ channel, ...rest }: IChannelMembersProps) {
    const classes = useStyles();
    const isConnectSearchUsersEnabled = useFeatureState(FeatureFlags.ConnectSearchUsers);
    const isConnectShowChannelCreatorEnabled = useFeatureState(FeatureFlags.ConnectShowChannelCreator);

    const prUsers = useSelector(selectUsersByIds);
    const actions = useActions({
        getUsers: requestMultipleUsersInfo.init,
    });
    const memberRolesAll = useSelector(selectChatRoleIds);
    const memberRoles = useMemo(() => {
        return memberRolesAll[channel?.id ?? ''];
    }, [memberRolesAll, channel]);
    const allRoles = useSelector(selectUserRoles);
    const [isActiveMembers, setIsActiveMembers] = useState(false);

    const [ searchMember, setSearchMember ] = useState<string>('');
    const [ membersByChannel, setMembersByChannel ] = useState<any>([]);
    const [isGettingMembers, setIsGettingMembers] = useState(false);

    const [currPage, setCurrPage] = useState<string>('');
    const [prevPage, setPrevPage] = useState<string>('0');
    const [wasLastList, setWasLastList] = useState(false);

    useEffect(() => {
        const absentList = membersByChannel.filter((member: any) => !prUsers[member.id]);
        if (absentList.length) {
            const idList = absentList.map((item: User) => item.id);
            actions.current.getUsers(idList);
        }

    }, [actions, prUsers, memberRoles, membersByChannel]);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getMemebers = useCallback(debounce(async (value: any, isActiveUsersFilter: boolean) => {
        if (!channel?.id) {
            return;
        }
        
        setIsGettingMembers(true);
        await searchMembers(channel, value, 0)
            .then((members: Array<UserResponse>) => {
                const filterMembers = filterDeactivatedMembers(members, isActiveUsersFilter)
                setMembersByChannel(filterMembers);
            })
            .finally(() => {
                setIsGettingMembers(false);
                setWasLastList(false);
                setPrevPage('0');
            });
    }, 1000), [channel, setIsGettingMembers]);
        
    const handleInputChange = useCallback((event: any) => {
        setSearchMember(event.target.value)
        if (isGettingMembers) {
            return;
        }
        getMemebers(event?.target?.value?.trim(), isActiveMembers);
    }, [getMemebers, isGettingMembers, isActiveMembers]);

    const onScroll = (scrollTop: number, scrollHeight: number, clientHeight: number) => {
        if (scrollTop + clientHeight === scrollHeight) {
            setCurrPage(membersByChannel[membersByChannel.length - 1].id);
        }
    };

    const fetchData = useCallback( async (isActiveUsersFilter: boolean) => {
        setIsGettingMembers(true);
        const responseMembers = await searchMembers(channel, searchMember, membersByChannel.length);
        if (!responseMembers.length) {
            setIsGettingMembers(false);
            setWasLastList(true);
            return;
        }
        const uniqItems = sortByOwner(uniqBy([...membersByChannel, ...responseMembers], 'id'));
        const filterMembers = filterDeactivatedMembers(uniqItems, isActiveUsersFilter)

        setPrevPage(currPage);
        setMembersByChannel(filterMembers);
        setWasLastList(false);
        setIsGettingMembers(false);
    },[membersByChannel, channel, searchMember, currPage]);

    useEffect(() => {
        if (!wasLastList && prevPage !== currPage && !isGettingMembers) {
            fetchData(isActiveMembers);
        }
    }, [currPage, wasLastList, prevPage, isGettingMembers, fetchData, isActiveMembers]);

    const adornment = useMemo(()=>(
        <>
            {isGettingMembers ? <CircularProgress color="inherit" size={20} /> : null}
        </>
    ),[isGettingMembers]);

    const handleChangeMembers = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked } } = event;
        setIsActiveMembers(checked);
        getMemebers(searchMember, checked)
    },[getMemebers, searchMember])

    return (
        <>
            <ChannelDetailsSection
                title={<><Trans>Members</Trans> ({isMultiplo100(membersByChannel.length)})</>}
                Icon={GroupIcon}
                {...rest}
            >
                <Box display="flex" flexDirection="column" width="100%">
                    { isConnectSearchUsersEnabled &&
                        <>
                            <TextField
                                value={searchMember}
                                onChange={handleInputChange}
                                variant="outlined"
                                placeholder={`${t('Search member')}`}
                                aria-describedby={`${t('search member')}`}
                                fullWidth
                                InputProps={{
                                    endAdornment: adornment,
                                }}
                                />
                            <Box>
                                <Switch
                                    edge="start"
                                    name="isActiveMembers"
                                    color="secondary"
                                    checked={isActiveMembers}
                                    onChange={handleChangeMembers}
                                />
                                <Typography variant="caption">
                                    <Trans>Show active members</Trans>
                                </Typography>
                            </Box>
                        </>
                        }
                    <Scrollbar
                        style={{maxHeight: '400px'}}
                        onScrollY={(container) =>{
                            onScroll(container.scrollTop, container.scrollHeight, container.clientHeight)
                        }}>
                            <List className={classes.list}>
                                {
                                    membersByChannel?.map((member: any) => renderMember(member, prUsers, isConnectShowChannelCreatorEnabled))
                                }
                            </List>
                    </Scrollbar>
                </Box>
            </ChannelDetailsSection>
            <ChannelDetailsSection
                title={<><Trans>Roles</Trans> ({memberRoles?.length ?? 0})</>}
                Icon={GroupIcon}
                {...rest}
            >
                <List className={classes.list}>
                    {
                        memberRoles?.map((member) => renderRole(member, allRoles))
                    }
                </List>
            </ChannelDetailsSection>
        </>
    );
}

function renderRole(memberId: string, allRoles: UserRoleDTO[]) {
    const roleItem = allRoles.find(x => x.roleId === memberId);
    return (
        <ChannelRoleMember
            key={memberId}
            id={memberId}
            name={roleItem?.description ?? ''}
        />
    );
}

function renderMember(member: User, prUsers: ReturnType<typeof selectUsersByIds>, showOwner: boolean) {
    const prUser = prUsers[member.id || ''];
    const jobTitle = prUser?.employeeTitle || prUser?.role || `${t('Channel member')}`;
    return (
        <ChannelMember
            key={member.id}
            id={member.id}
            name={member.name || `${t('Unknown')}`}
            avatarUrl={member.image as string || ''}
            jobTitle={jobTitle}
            isAdmin={member.userRole === UserPermissionChannel.Admin}
            isOwner={member.role === UserPermissionChannel.Owner && showOwner}
            channelCreatedAt={member?.channel_created_at as string || ''}
            deactivatedUser={!!member?.deactivated_at}
        />
    );
}
