import { put, takeLatest, select, call } from 'typed-redux-saga';
import { selectTaskFilter } from './taskFilterSelectors';
import { TASK_LIST_SET_PARENT_TASKS_SORTING, TASK_LIST_SET_STORE_TASKS_SORTING, parentTaskListSetSorting, storeTaskListSetSorting } from '../taskListComponent/taskListComponentActions';
import { getDefaultTaskFilter, requestUpdateTaskFilter } from 'api/task/taskFilter/taskFilterActions';
import { selectParentTasksSortBy, selectStoreTasksSortBy } from '../taskListComponent/taskListComponentSelectors';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { DefaultSortByParentTask, TasksFilterType, DefaultSortByStoreTask } from 'api/task/taskFilter/taskFilterModel';
import { TaskListSortBy } from '../taskListComponent/taskListComponentModel';
import { FeatureFlags } from 'utils/featureFlags';
import { selectUser } from 'store/modules/auth/authSelectors';
import { TASK_FILTER_TAB, taskFilterTab } from './taskFilterActions';

function* taskFilterWatcher() {
    yield* takeLatest(
        getDefaultTaskFilter.successType,
        function* () {
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const isTasksFilterPersistenceEnabled = featureFlags[FeatureFlags.TasksFilterPersistence]?.enabled;
            if( isTasksFilterPersistenceEnabled ){
                const currentUser = yield* select(selectUser);
                const isStore = currentUser?.isCompanyLocationStore === true;
                const tasksFilters: TasksFilterType = yield* select(selectTaskFilter);

                if(!isStore || currentUser.isSuperAdmin){
                    const sorting = tasksFilters.sortBy === DefaultSortByStoreTask || !tasksFilters.sortBy
                        ? { sortBy: DefaultSortByParentTask, descending: false } 
                        : { sortBy: tasksFilters.sortBy, descending: tasksFilters.descending };
                    yield put(parentTaskListSetSorting({
                        id: sorting.sortBy,
                        desc: sorting.descending
                    }))
                }else{
                    const sorting = tasksFilters.sortBy === DefaultSortByParentTask || !tasksFilters.sortBy
                        ? { sortBy: DefaultSortByStoreTask, descending: false } 
                        : { sortBy: tasksFilters.sortBy, descending: tasksFilters.descending };
                    yield put(storeTaskListSetSorting({
                        id: sorting.sortBy,
                        desc: sorting.descending
                    }))
                }
            }
        }
    );
}

function* updateTasksFilterPersistence (taskFilterSorting: TaskListSortBy) {
    const taskFilter: TasksFilterType = yield* select(selectTaskFilter);
    const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
    const isTasksFilterPersistenceEnabled = featureFlags[FeatureFlags.TasksFilterPersistence]?.enabled;
    if(taskFilterSorting !== 'not-set' && isTasksFilterPersistenceEnabled &&
        (taskFilter.sortBy !== taskFilterSorting?.id || taskFilter.descending !== taskFilterSorting?.desc)){
        const payload = {
            taskFilter: { ...taskFilter, sortBy: taskFilterSorting?.id as string, descending: taskFilterSorting?.desc || false},
            hasFlagEnabled: isTasksFilterPersistenceEnabled
        }
        yield put(requestUpdateTaskFilter.init(payload));
    }
}

function* taskFilterSortingWatcher() {
    yield* takeLatest(
        TASK_LIST_SET_PARENT_TASKS_SORTING,
        function* () {
            const taskFilterSorting: TaskListSortBy = yield* select(selectParentTasksSortBy);

            yield call(updateTasksFilterPersistence, taskFilterSorting);
        }
    );
}

function* taskStoreFilterSortingWatcher() {
    yield* takeLatest(
        TASK_LIST_SET_STORE_TASKS_SORTING,
        function* () {
            const currentUser = yield* select(selectUser);
            const isStore = currentUser?.isCompanyLocationStore === true;
            if(isStore){
                const taskFilterSorting: TaskListSortBy = yield* select(selectStoreTasksSortBy);

                yield call(updateTasksFilterPersistence, taskFilterSorting);
            }
        }
    );
}

function* taskFilterTabWatcher() {
    yield* takeLatest(
        TASK_FILTER_TAB,
        function* ({ payload }: ReturnType<typeof taskFilterTab>) {
            const valuesFiltersTasks: TasksFilterType =  yield* select(selectTaskFilter);
            const taskFilterSorting: TaskListSortBy = yield* select(selectParentTasksSortBy);

            const filterPayload = { taskFilter: { ...valuesFiltersTasks, tasksViewMode: payload }, hasFlagEnabled: true }
            yield put(requestUpdateTaskFilter.init(filterPayload));
            yield call(updateTasksFilterPersistence, taskFilterSorting);

        }
    );
}



export const taskFilterSagas = [
    taskFilterWatcher,
    taskFilterSortingWatcher,
    taskStoreFilterSortingWatcher,
    taskFilterTabWatcher
];
