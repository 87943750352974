import { useSelector } from 'react-redux';
import { nonEmpty } from 'utils/helpers/collections';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { selectCalendarEventById, selectCalendarEventOrder, selectCalendarTasksAutomatedById, selectCalendarTasksAutomatedOrder, selectCalendarTasksById, selectCalendarTasksOrder, selectCalendarTasksScheduledById, selectCalendarTasksScheduledOrder } from 'store/modules/task/calendarTask/calendarSelectors';
import { CalendarEventShort, CalendarTaskRegularResponse, CalendarTaskScheduledResponse, CalendarTaskUserResponse } from 'api/task/calendarTask/calendaTaskModel';

export function useCalendarTasksData() {
    const calendarTasksRegularOrder = useSelector(selectCalendarTasksOrder);
    const calendarTasksById = useSelector(selectCalendarTasksById);
    const calendarTasksScheduledOrder = useSelector(selectCalendarTasksScheduledOrder);
    const calendarTasksScheduledById = useSelector(selectCalendarTasksScheduledById);
    const calendarTasksAutomatedOrder = useSelector(selectCalendarTasksAutomatedOrder);
    const calendarTasksAutomatedById = useSelector(selectCalendarTasksAutomatedById);
    const calendarEventOrder = useSelector(selectCalendarEventOrder);
    const calendarEventById = useSelector(selectCalendarEventById);

    const calendarTasks: Array<any> = (
        calendarTasksRegularOrder
            .map((id: string) => calendarTasksById[id])
            .filter(nonEmpty)
            .map((item: CalendarTaskRegularResponse) => (adapterToCalendar(item)))
    );
    const calendarTasksScheduled: Array<any> = (
        calendarTasksScheduledOrder
            .map((id: string) => calendarTasksScheduledById[id])
            .filter(nonEmpty)
            .map((item: CalendarTaskScheduledResponse) => (adapterToCalendar(item)))
    );
    const calendarTasksAutomated: Array<any> = (
        calendarTasksAutomatedOrder
            .map((id: string) => calendarTasksAutomatedById[id])
            .filter(nonEmpty)
            .map((item: CalendarTaskUserResponse) => (adapterToCalendar(item)))
    );
    const calendarEvent: Array<any> = (
        calendarEventOrder
            .map((id: string) => calendarEventById[id])
            .filter(nonEmpty)
            .map((item) => (adapterToCalendar(item as CalendarEventShort)))
    );

    return [...calendarTasks, ...calendarTasksScheduled, ...calendarTasksAutomated, ...calendarEvent];
}


const adapterToCalendar = (item: CalendarTaskRegularResponse | CalendarTaskScheduledResponse | CalendarTaskUserResponse | CalendarEventShort) => ({
    id: item.id,
    title: item.title,
    completionState: DisplayedTaskStatus.Default,
    tag: item.tag,
    storeTasksCount: item?.storeTasksCount,
    start:item?.createdAt,
    end: item?.dueDate,
    movable: false,
    resizable: false,
    allDay: true,
    taskType: item.taskType,
    prototypeId: item.prototypeId,
    canEdit: 'canEdit' in item ? item?.canEdit : false
});
