import { delay, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { CALENDAR_COMPONENT_ACTIVE_VIEW, CALENDAR_COMPONENT_ON_NAVIGATE, CALENDAR_FILTER, CALENDAR_LOCATION_IDS_SELECTED, EVENT_ID_MODAL, GET_CALENDAR_TASK, calendarComponentActiveView, calendarComponentLocationIdsSelected, calendarComponentOnNavigate, eventModalActiveId, getCalendarTasks, resetTasksScheduled } from './calendarTaskActions';
import { dateRangeByView } from 'modules/task/taskCalendar/updateDataToScheduler';
import { requestCalendarCreateEvent, requestCalendarEvent, requestCalendarTasksRegular, requestCalendarTasksScheduled, requestCalendarTasksUser, requestGetCalendarEvent, requestUpdateCalendarEvent } from 'api/task/calendarTask/calendaTaskActions';
import { selectCalendarComponentActiveView, selectCalendarComponentOnNavigate, selectCalendarFilter, selectCalendarLocationIdsSelected, selectCalendarTasksFetching } from './calendarSelectors';
import { TasksFilterType } from 'api/task/taskFilter/taskFilterModel';
import { View, Views } from 'react-big-calendar';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { ICalendarComponentOnNavigate } from './calendarTaskModel';
import { UserDTO } from 'api/user/userModel';
import { selectUser } from 'store/modules/auth/authSelectors';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { resetPendingFiles, resetUploadedFiles } from 'store/modules/files/filesActions';
import { Routes, browserHistory, routeNames } from 'components/router/model';
import { generatePath } from 'react-router-dom';
import { navigateTo, showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import i18n from 'i18n';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { FeatureFlags } from 'utils/featureFlags';
import { selectUserById } from 'store/modules/user/userSelectors';
import { requestMultipleUsersInfo } from 'api/user/userActions';

function* calendarTasksGetWatcher() {
    yield* takeEvery(
        GET_CALENDAR_TASK,
        function* (action: ReturnType<typeof getCalendarTasks>) {
            const rangeDate = dateRangeByView(Views.MONTH);
            const dateRange: {dateStart: string, dateEnd: string} = action.payload.dateStart && action.payload.dateEnd ? 
                {dateStart: action.payload.dateStart, dateEnd: action.payload.dateEnd}
                : rangeDate;
            const currentUser: UserDTO | null | undefined = yield* select(selectUser);
            const useCanAccessScheduledTasks: boolean = yield* select(selectHasAppAccess(AppName.TasksCreateDelete));
            const useCanAccessTasks: boolean = yield* select(selectHasAppAccess(AppName.Tasks));
            const isselectCalendarFetching = yield select(selectCalendarTasksFetching);
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const isCalendarEventEnabled = featureFlags[FeatureFlags.CalendarEvents]?.enabled;

            let filterTag = getFilterTag(action.payload?.tags, action.payload.filter);

            if(!currentUser?.companyLocationId || isselectCalendarFetching) return;
            yield put(resetTasksScheduled());

            const locationIdsSelected: string[] = yield select(selectCalendarLocationIdsSelected);
            const locationIds: string[] = locationIdsSelected.length ? locationIdsSelected : [currentUser?.companyLocationId];

            if(useCanAccessTasks){
                yield put(requestCalendarTasksRegular.init({...dateRange, tags: filterTag.tags, filter: filterTag.filter, locations: locationIds}));
            }

            if(useCanAccessScheduledTasks && !filterTag.filter){
                yield put(requestCalendarTasksScheduled.init({...dateRange, tags: filterTag.tags, locations: locationIds}));
            }


            yield put(requestCalendarTasksUser.init({...dateRange, filter: filterTag.filter}));
            if(isCalendarEventEnabled){
                yield put(requestCalendarEvent.init({...dateRange, tags: filterTag.tags, filter: filterTag.filter, locations: locationIds}));
            }
        },
    );
}

function getFilterTag (tags: string[] = [], filter?: AutomationTaskStatusFilter) {
    let tagsToRequest: string[] = [];
    let filterToRequest = undefined;
    const activeRoute: any = browserHistory?.location?.pathname;
    if(activeRoute in routeNames){
        return { tags: tagsToRequest, filter: filterToRequest}
    }
    return { tags, filter: !filter ? undefined : filter}
}

function* calendarTasksGetOnNavigateWatcher() {
    yield* takeEvery(CALENDAR_COMPONENT_ON_NAVIGATE,function* ({ payload }: ReturnType<typeof calendarComponentOnNavigate>) {
            const { view, newDate, action } = payload;
            const viewAction = action === 'DATE' ? Views.DAY : view;
            const rangeDate = dateRangeByView(viewAction, newDate);
            const activeFilters: TasksFilterType = yield select(selectCalendarFilter);
            const formatPayload = { 
                tags: activeFilters.tags,
                filter: activeFilters.filter as AutomationTaskStatusFilter | undefined,
                ...rangeDate,
                locations: undefined
            };

            yield* put(getCalendarTasks(formatPayload));

            if(action === 'DATE'){
                yield* delay(1000);
                yield* put(getCalendarTasks(formatPayload))
            }
        },
    );
}

function* calendarTasksGetOnChangeViewWatcher() {
    yield* takeEvery(CALENDAR_COMPONENT_ACTIVE_VIEW,function* ({ type, payload }: ReturnType<typeof calendarComponentActiveView>) {
            const onNavigate: ICalendarComponentOnNavigate = yield select(selectCalendarComponentOnNavigate);
            const rangeDate = dateRangeByView(payload, onNavigate?.newDate ?? undefined);

            const activeFilters: TasksFilterType = yield select(selectCalendarFilter)
            const formatPayload = {
                tags: activeFilters.tags,
                filter: activeFilters.filter as AutomationTaskStatusFilter | undefined,
                ...rangeDate,
                locations: undefined
            };

            yield* put(getCalendarTasks(formatPayload))
        },
    );
}

function* calendarLocationsSelectedWatcher() {
    yield* takeEvery([CALENDAR_LOCATION_IDS_SELECTED, CALENDAR_FILTER],function* ({ payload }: ReturnType<typeof calendarComponentLocationIdsSelected>) {
            const onNavigate: ICalendarComponentOnNavigate = yield select(selectCalendarComponentOnNavigate);
            const activeView: View = yield select(selectCalendarComponentActiveView);
            const rangeDate = dateRangeByView(activeView, onNavigate?.newDate ?? undefined);
            const activeFilters: TasksFilterType = yield select(selectCalendarFilter);
            const formatPayload = {
                tags: activeFilters.tags,
                filter: activeFilters.filter as AutomationTaskStatusFilter | undefined,
                ...rangeDate,
                locations: undefined
            };

            yield* put(getCalendarTasks(formatPayload))
        },
    );
}

function* calendarEventCreateSuccessSaga() {
    yield* takeLatest(
        requestCalendarCreateEvent.successType,
        function* () {
            yield put(resetPendingFiles());
            yield put(resetUploadedFiles());
            browserHistory.push(generatePath(Routes.TaskListCalendar));
            yield put(showToastMessage({
                message: i18n.t('Calendar Event successfully created'),
                options: {
                    variant: ToastVariant.Success,
                },
            }));
        },
    );
}

export function* updateCalendarEventWatcherSaga() {
    yield takeEvery(
        requestUpdateCalendarEvent.successType,
        function* () {
            yield put(navigateTo(generatePath(Routes.TaskListCalendar)));
            yield put(showToastMessage({
                message: i18n.t('Event Updated'),
                options: {
                    variant: ToastVariant.Success
                },
            }))
        },
    );
}

function* getEventDetailsSuccessWatcher() {
    yield* takeEvery(
        EVENT_ID_MODAL,
        function* (action: ReturnType<typeof eventModalActiveId>) {
            if(!action.payload) return;
            yield put(requestGetCalendarEvent.init(action.payload))
        },
    );
}

function* eventDetailsSuccessWatcher() {
    yield* takeEvery(
        requestGetCalendarEvent.successType,
        function* (action: ReturnType<typeof requestGetCalendarEvent.success>) {
            const userId = action.payload.createdBy;
            const user = yield select(selectUserById(userId));
            if (!user) {
                yield put(requestMultipleUsersInfo.init([userId]));
            }
        },
    );
}

export const calendarTaskSagas = [
    calendarTasksGetWatcher,
    calendarTasksGetOnNavigateWatcher,
    calendarTasksGetOnChangeViewWatcher,
    calendarLocationsSelectedWatcher,
    calendarEventCreateSuccessSaga,
    updateCalendarEventWatcherSaga,
    eventDetailsSuccessWatcher,
    getEventDetailsSuccessWatcher
];
