import { ActionsReturnTypes } from "utils/store/actionUtils";
import {
    createApiActions,
    createApiActionsByName,
} from "utils/api/apiActionUtils";
import { IResponseErrorPayload } from "model/error";
import {
    CalendarEvent,
    CalendarEventEditResponse,
    CalendarEventShort,
    CalendarTaskRegularRequest,
    CalendarTaskRegularResponse,
    CalendarTaskScheduledRequest,
    CalendarTaskScheduledResponse,
    CalendarTaskUserRequest,
    CalendarTaskUserResponse,
    CreateCalendarEvent,
    CreateCalendarResponseEvent,
} from "./calendaTaskModel";
import { IEntityError, IEntitySuccess } from "model/entity";

const REQUEST_CALENDAR_TASKS_REGULAR_LIST =
    "request/task/calendar/regular/GET_LIST";
const RESPONSE_CALENDAR_TASKS_REGULAR_LIST_SUCCESS =
    "response/task/calendar/regular/GET_LIST_SUCCESS";
const RESPONSE_CALENDAR_TASKS_REGULAR_LIST_ERROR =
    "response/task/calendar/regular/GET_LIST_ERROR";

export const requestCalendarTasksRegular = createApiActions<
    CalendarTaskRegularRequest,
    CalendarTaskRegularResponse[],
    IResponseErrorPayload,
    typeof REQUEST_CALENDAR_TASKS_REGULAR_LIST,
    typeof RESPONSE_CALENDAR_TASKS_REGULAR_LIST_SUCCESS,
    typeof RESPONSE_CALENDAR_TASKS_REGULAR_LIST_ERROR
>(
    REQUEST_CALENDAR_TASKS_REGULAR_LIST,
    RESPONSE_CALENDAR_TASKS_REGULAR_LIST_SUCCESS,
    RESPONSE_CALENDAR_TASKS_REGULAR_LIST_ERROR
);

const REQUEST_CALENDAR_TASKS_SCHEDULED_LIST =
    "request/task/calendar/scheduled/GET_LIST";
const RESPONSE_CALENDAR_TASKS_SCHEDULED_LIST_SUCCESS =
    "response/task/calendar/scheduled/GET_LIST_SUCCESS";
const RESPONSE_CALENDAR_TASKS_SCHEDULED_LIST_ERROR =
    "response/task/calendar/scheduled/GET_LIST_ERROR";

export const requestCalendarTasksScheduled = createApiActions<
    CalendarTaskScheduledRequest,
    CalendarTaskScheduledResponse[],
    IResponseErrorPayload,
    typeof REQUEST_CALENDAR_TASKS_SCHEDULED_LIST,
    typeof RESPONSE_CALENDAR_TASKS_SCHEDULED_LIST_SUCCESS,
    typeof RESPONSE_CALENDAR_TASKS_SCHEDULED_LIST_ERROR
>(
    REQUEST_CALENDAR_TASKS_SCHEDULED_LIST,
    RESPONSE_CALENDAR_TASKS_SCHEDULED_LIST_SUCCESS,
    RESPONSE_CALENDAR_TASKS_SCHEDULED_LIST_ERROR
);

const REQUEST_CALENDAR_TASKS_USER_LIST = "request/task/calendar/user/GET_LIST";
const RESPONSE_CALENDAR_TASKS_USER_LIST_SUCCESS =
    "response/task/calendar/user/GET_LIST_SUCCESS";
const RESPONSE_CALENDAR_TASKS_USER_LIST_ERROR =
    "response/task/calendar/user/GET_LIST_ERROR";

export const requestCalendarTasksUser = createApiActions<
    CalendarTaskUserRequest,
    CalendarTaskUserResponse[],
    IResponseErrorPayload,
    typeof REQUEST_CALENDAR_TASKS_USER_LIST,
    typeof RESPONSE_CALENDAR_TASKS_USER_LIST_SUCCESS,
    typeof RESPONSE_CALENDAR_TASKS_USER_LIST_ERROR
>(
    REQUEST_CALENDAR_TASKS_USER_LIST,
    RESPONSE_CALENDAR_TASKS_USER_LIST_SUCCESS,
    RESPONSE_CALENDAR_TASKS_USER_LIST_ERROR
);

const REQUEST_CALENDAR_EVENT_LIST = "request/task/calendar/event/GET_LIST";
const RESPONSE_CALENDAR_EVENT_LIST_SUCCESS =
    "response/task/calendar/event/GET_LIST_SUCCESS";
const RESPONSE_CALENDAR_TEVENTLIST_ERROR =
    "response/task/calendar/event/GET_LIST_ERROR";

export const requestCalendarEvent = createApiActions<
    CalendarTaskRegularRequest,
    CalendarEventShort[],
    IResponseErrorPayload,
    typeof REQUEST_CALENDAR_EVENT_LIST,
    typeof RESPONSE_CALENDAR_EVENT_LIST_SUCCESS,
    typeof RESPONSE_CALENDAR_TEVENTLIST_ERROR
>(
    REQUEST_CALENDAR_EVENT_LIST,
    RESPONSE_CALENDAR_EVENT_LIST_SUCCESS,
    RESPONSE_CALENDAR_TEVENTLIST_ERROR
);

const CALENDAR_CREATE_EVENT = `request/calendar/CREATE_EVENT`;
export const requestCalendarCreateEvent = createApiActionsByName<
    CreateCalendarEvent,
    CreateCalendarResponseEvent,
    any,
    typeof CALENDAR_CREATE_EVENT
>(CALENDAR_CREATE_EVENT);

const CALENDAR_GET_EVENT = "request/calendar/GET_EVENT";
export const requestGetCalendarEvent = createApiActionsByName<
    string,
    CalendarEvent,
    IEntityError,
    typeof CALENDAR_GET_EVENT
>(CALENDAR_GET_EVENT);

const CALENDAR_UPDATE_EVENT = "request/calendar/UPDATE_EVENT";
export const requestUpdateCalendarEvent = createApiActionsByName<
    CalendarEvent,
    CalendarEventEditResponse,
    IEntityError,
    typeof CALENDAR_UPDATE_EVENT
>(CALENDAR_UPDATE_EVENT);

const CALENDAR_EVENT_DELETE = 'request/calendar/DELETE_EVENT';
export const requestDeleteCalendarEvent = (
    createApiActionsByName<string, IEntitySuccess, IEntityError , typeof CALENDAR_EVENT_DELETE>(
        CALENDAR_EVENT_DELETE,
    )
);

export type CalendarApiActions =
    | ActionsReturnTypes<typeof requestCalendarTasksRegular>
    | ActionsReturnTypes<typeof requestCalendarTasksScheduled>
    | ActionsReturnTypes<typeof requestCalendarTasksUser>
    | ActionsReturnTypes<typeof requestCalendarEvent>
    | ActionsReturnTypes<typeof requestCalendarCreateEvent>
    | ActionsReturnTypes<typeof requestGetCalendarEvent>
    | ActionsReturnTypes<typeof requestUpdateCalendarEvent>
    | ActionsReturnTypes<typeof requestDeleteCalendarEvent>;
