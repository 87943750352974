import memoize from 'lodash/memoize';
import { TASK_DATE_FORMAT, TASK_DATE_FORMAT_YYYYMMDD } from 'modules/task/common/form/validation';
import moment, { DurationInputArg2 } from 'moment';

export function isExpired(unixTimestamp: number): boolean {
    const expireDate = moment.unix(unixTimestamp);
    const now = moment.utc();
    return now.isAfter(expireDate);
}

export function getSecondsToExpiration(unixTimestamp: number): number | null {
    const unixNow = Math.floor(new Date().getTime() / 1000);
    if (unixNow < unixTimestamp) {
        return unixTimestamp - unixNow;
    }
    return null;
}

export function getNumberOfTheDay(date: string): number {
    let nOfTheDay = 1;
    let momentDate = moment(date);
    const month = momentDate.month();

    while (momentDate.subtract(1, 'week').month() === month) {
        nOfTheDay += 1;
    }

    return nOfTheDay;
}

const numberFormatOptions: Record<number, string> = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'fifth',
};

export function getNumberOfTheDayFormatted(
    date: string,
    formatOptions: Record<number, string> = numberFormatOptions,
): string {
    return formatOptions[getNumberOfTheDay(date)];
}

export const timeFormat = 'h:mma';
const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};
export const shortDateFormat: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
};

const checkToLocaleDateStringSupport = memoize((): boolean => {
    try {
        new Date().toLocaleDateString(undefined, shortDateFormat);
    } catch {
        return false;
    }

    return true;
});

export const fullDateByBrowser = (date: string | Date) => {
    const momentDate = moment.utc(date);
    try {
        const localeDate = momentDate.toDate().toLocaleDateString(undefined, timeOptions);
        return localeDate;
    } catch {
        return momentDate.format('MM/DD/YYYY HH:mm');
    }
}

export const formatDateLocalized = (date: string | Date, format: Intl.DateTimeFormatOptions): string => {
    const isLocalizationSupported = checkToLocaleDateStringSupport();
    const momentDate = moment(date);

    return isLocalizationSupported
        ? momentDate.toDate().toLocaleDateString(undefined, format)
        : momentDate.format('MM/DD/YYYY'); // fallback to default (USA) short date format
};

export const formatDateCustom = (date: string | Date, format: string): string => {
    return moment(date).format(format);
};

export const isBeforeDate = (dateStart: string | Date, dateEnd: string | Date): boolean => {
    return moment(dateEnd).isBefore(dateStart);
};

export const addDate = (
    startdate: string | Date,
    count: number,
    formatDate = TASK_DATE_FORMAT,
    type: DurationInputArg2 = "days"
): string =>{
    const startDateMoment = moment(startdate, formatDate);
    const newDate = moment(startDateMoment).add(count, type);
    return newDate.format(formatDate)
}

export const firstDayOfTheMonth = () => moment().format("YYYY-MM-01");

export const todayMoment = (formatDate = TASK_DATE_FORMAT_YYYYMMDD) => moment().format(formatDate);
