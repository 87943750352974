import { Location } from 'api/location/locationModel';
import { ItemsById } from 'model/entity';
import { findNode, getAllParents, LeafNode } from 'utils/helpers/trees';
import { IPickItemProps } from '../PickItem/types';
import { nonEmpty } from 'utils/helpers/collections';
import { Store, Stores } from 'api/store/storeModel';

export function buildLocationsTree(
    currentLocationId: string,
    relationByIds: Record<string, Array<string>>,
    parent: LeafNode<string> | null = null,
): LeafNode<string> {
    const node = new LeafNode(currentLocationId, parent);
    if (currentLocationId in relationByIds) {
        const children = relationByIds[currentLocationId].map(id => buildLocationsTree(id, relationByIds, node));
        node.children = children;
    }
    return node;
}

export function mapLocationsTreeToPickItems(
    locationsTreeRoot: LeafNode<string>,
    locationsByIds: ItemsById<Location>,
    selectedItems: Array<string>,
    setSelected: (id: string, selected: boolean) => void,
    expandedItems: Array<string>,
    setExpanded: (id: string, expanded: boolean) => void,
): Array<IPickItemProps> {
    return (
        locationsTreeRoot
            .children
            .filter(item => item.value in locationsByIds)
            .map(item => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const location = locationsByIds[item.value]!;
                const pickItem: IPickItemProps = {
                    id: item.value,
                    title: location.name,
                    storesCount: location.storesCount,
                    isStore: location.isStore,
                    selected: selectedItems.includes(item.value),
                    setSelected: setSelected,
                    expanded: expandedItems.includes(item.value),
                    setExpanded: setExpanded,
                    items: mapLocationsTreeToPickItems(
                        item,
                        locationsByIds,
                        selectedItems,
                        setSelected,
                        expandedItems,
                        setExpanded,
                    ),
                };
                return pickItem;
            })
            .filter(item => item.isStore || item.storesCount > 0)
    );
}

const searchParent = (obj: Store, parentId: string): boolean => {
    while (obj.parent !== null) {
        if (obj.parent.id === parentId) {
        return true;
        }
        obj = obj.parent;
    }
    return false;
}

export const countMapByMainLocation = (
    companyLocationId: string,
    relationsByIds: Record<string, Array<string>>,
    topSelectedLocations: Array<string>,
    locationsByIds: ItemsById<Location>,
    locationsTree: LeafNode<string> | null,
    selectSearchStore: Stores
)=>{
    const mainParent = relationsByIds[companyLocationId ?? ''] || [];
    const keyMainparent: Map<string, number> = new Map<string, number>();
    mainParent.forEach(p=>{keyMainparent.set(p, 0)});
    const countMainChildren = topSelectedLocations
        .map(id => locationsByIds[id] )
        .filter(nonEmpty);
    countMainChildren.forEach(loc =>{
        const predicate = (itemId: string) => itemId === loc.locationId;
        const node = locationsTree ? findNode(locationsTree, predicate) : null;
        const allParents = node ? getAllParents(node).map(item => item.value) : [];
        for(let i in allParents){
            if(keyMainparent.has(allParents[i])){
                keyMainparent.set(allParents[i], (keyMainparent.get(allParents[i]) || 0) + loc.storesCount);
                continue;
            }
            if(allParents[i] === companyLocationId && allParents.length === 1){
                keyMainparent.set(loc.locationId, (keyMainparent.get(loc.locationId) || 0) + loc.storesCount);
            }
        }
    })
    selectSearchStore.forEach(loc=>{
        const predicate = (itemId: string) => itemId === loc.id;
        const node = locationsTree ? findNode(locationsTree, predicate) : null;
        if(!node && !countMainChildren.some(l => JSON.stringify(loc.parent).includes(l.locationId))){
            for (const [key] of keyMainparent.entries()) {
                if(searchParent(loc, key)){
                    keyMainparent.set(key, (keyMainparent.get(key) || 0) + 1);
                }
            }
        }
    })
    return keyMainparent;
}

type StoreMap = { [key: string]: Store };

const createStoreElement = (location: Location, parent: Store | null = null): Store => ({
    id: location.locationId,
    isStore: location.isStore,
    name: location.name,
    parent: parent,
});

const getOrCreateStore = (storeMap: StoreMap, location: Location): Store => {
    if (!storeMap[location.locationId]) {
        storeMap[location.locationId] = createStoreElement(location);
    }
    return storeMap[location.locationId];
};

const processNodeParents = (
    storeId: string, 
    locationsTree: LeafNode<string> | null,
    locationsUserTreeById: ItemsById<Location>,
    storeMap: StoreMap,
    currentStore: Store
): Store => {
    const predicate = (itemId: string) => itemId === storeId;
    const node = locationsTree ? findNode(locationsTree, predicate) : null;
    const nodeParents = node ? getAllParents(node).map(item => item.value) : [];

    for (const nodeParent of nodeParents) {
        const parent = locationsUserTreeById[nodeParent];
        if (parent) {
            const parentStore = getOrCreateStore(storeMap, parent);
            if (currentStore.parent === null) {
                currentStore.parent = parentStore;
            }
            currentStore = parentStore;
        }
    }
    return currentStore;
};

export const getChildrenFromBottom = (
    locationsTree: LeafNode<string> | null,
    locationsUserTreeById: ItemsById<Location>,
    storeIds: string[],
): Stores => {
    const storeTree: Stores = [];
    const storeMap: { [key: string]: Store } = {};

    for (const storeId of storeIds) {
        const store = locationsUserTreeById[storeId];
        if (store) {
            let currentStore = storeMap[storeId] || createStoreElement(store);
            if (!storeMap[storeId]) {
                storeMap[storeId] = currentStore;
                if (currentStore.isStore) {
                    storeTree.push(currentStore);
                }
            }

            processNodeParents(storeId, locationsTree, locationsUserTreeById, storeMap, currentStore);
        }
    }

    return storeTree;
};
