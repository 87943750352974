import {
    all, call, put, SagaGenerator, spawn, takeEvery,
} from 'typed-redux-saga';
import { logger } from 'utils/logger';
import { authSagas } from 'store/modules/auth/authSagas';
import { apiCommunicationSagas } from 'store/apiCommunication/apiCommunicationSagas';
import { hierarchySagas } from 'store/modules/hierarchy/hierarchySagas';
import { taskSagas } from 'store/modules/task/taskSagas';
import { filesSagas } from 'store/modules/files/filesSagas';
import { AnyAction } from 'redux';
import { ActionWithPayload } from 'utils/store/actionUtils';
import { IResponseErrorPayload } from 'model/error';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { companySagas } from 'store/modules/company/companySagas';
import { userSagas } from 'store/modules/user/userSagas';
import { appStateSagas } from 'store/modules/appState/appStateSagas';
import { getStreamSagas } from 'store/modules/getStream/getStreamSagas';
import { knockSagas } from './modules/knock/knockSagas';
import { featureFlagsSagas } from './modules/featureFlags/sagas';
import { launchSagas } from './modules/launch/launchSagas';

type RunnableSaga = () => Generator<SagaGenerator<any>, void>;

export function* processApiErrors() {
    yield takeEvery(
        (action: AnyAction) => action.type.match(/^response\/.*_ERROR$/),
        function* (action: ActionWithPayload<any>) {
            if (action.payload?.displayedMessage) {
                const errorPayload = action.payload as IResponseErrorPayload;
                if (errorPayload.showToast) {
                    yield put(showToastMessage({
                        message: errorPayload.displayedMessage, options: { variant: ToastVariant.Error },
                    }));
                }
                if (errorPayload.createError) {
                    logger.reportMessage(errorPayload.error.message, errorPayload);
                }
            }
        });
}

export function* displaySuccessMessages() {
    yield takeEvery(
        (action: AnyAction) => action.type.match(/^response\/.*_SUCCESS$/),
        function* (action: ActionWithPayload<any>) {
            if (action.payload?.displayedMessage) {
                yield put(showToastMessage({
                    message: action.payload.displayedMessage, options: { variant: ToastVariant.Success },
                }));
            }
        },
    );
}

export function* rootSaga() {
    const sagas: Array<RunnableSaga> = [
        ...featureFlagsSagas,
        ...authSagas,
        ...knockSagas,
        ...apiCommunicationSagas,
        ...hierarchySagas,
        ...taskSagas,
        ...filesSagas,
        ...companySagas,
        ...userSagas,
        ...appStateSagas,
        ...getStreamSagas,
        ...launchSagas,
        processApiErrors,
        displaySuccessMessages,
    ] as any;

    yield all(sagas.map(saga => spawn(function* () {
        while (true) {
            try {
                yield call(saga);
                break;
            } catch (e) {
                logger.reportError(e, { name: saga.name, root: 'Root Saga' });
            }
        }
    })));
}
