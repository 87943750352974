import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUsersByIds } from 'store/modules/user/userSelectors';
import { getUserName, getUserRole } from 'utils/helpers/user';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { UserInfoWithImage } from './UserInfoWithImage';
import { UserInfoSkeleton } from './UserInfoSkeleton';
import logoPR from 'images/Logo/LogoPR.png';
import { USER_AUTOMATION } from 'modules/task/taskList/components/AutomationTaskGrid/utils/automationUser';

export type UserInfoProps = {
    className?: string;
    userId: string;
    showInformation?: boolean;
    children?: ReactNode;
}

export function UserInfo({ className, userId, showInformation, children }: UserInfoProps) {
    const user = useSelector(selectUsersByIds)[userId];
    const fileStorageConfig = useSelector(getFilesConfig);
    const avatarUrl = useMemo(() => {
        return userId === USER_AUTOMATION ? logoPR : fileStorageConfig && user?.avatarId
            ? `${fileStorageConfig.avatarsBaseUrl}/${user.avatarId}`
            : '';
    }, [fileStorageConfig, user, userId])

    if (!user) {
        return <UserInfoSkeleton className={className} />;
    }

    return (
        <UserInfoWithImage
            className={className}
            avatarUrl={avatarUrl}
            title={getUserName(user)}
            subtitle={getUserRole(user)}
            lastName={user.lastName!}
            firstName={user.firstName!}
            showInformation={showInformation}
        >
            {children}
        </UserInfoWithImage>
    );
}
